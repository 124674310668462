import React, {memo, forwardRef} from 'react';
import Styled from '../styled-components';

const StyledInput = Styled.input
  .withConfig({
    shouldForwardProp: prop =>
      ![
        'caretHidden',
        'placeholderTextColor',
        'secureTextEntry',
        'keyboardType',
        'overrideStyle',
        'styles',
        'numberOfLines',
        'showSoftInputOnFocus',
        'editable',
      ].includes(prop),
  })
  .attrs(({readOnly, showSoftInputOnFocus, editable, numberOfLines}) => {
    let extraProps = {};
    extraProps.readOnly =
      readOnly || showSoftInputOnFocus === false || editable === false;
    if (numberOfLines) {
      extraProps.maxRows = numberOfLines;
    }
    return extraProps;
  })`
  padding: 0px;
  border-width: 0;
  background-color: transparent;
  outline:none;
  &::placeholder {
    color: ${({placeholderTextColor}) => placeholderTextColor};
  }
  ${({inputStyle}) => inputStyle}
  ${({caretHidden}) => (caretHidden ? {caretColor: 'transparent'} : {})}
  ${({readOnly}) => (readOnly ? {cursor: 'inherit'} : {})}
  ${({overrideStyle}) => overrideStyle}
`;

const TextInput = (
  {
    value,
    style,
    onChange: onChangeProp,
    onChangeText,
    onChangeValue,
    onKeyUp: onKeyUpProp,
    onSubmitEditing,
    ...props
  },
  ref,
) => {
  if (value === null || value === undefined) {
    value = '';
  } else if (value && typeof value === 'object') {
    value = JSON.stringify(value);
  }

  const onChange = e => {
    let text = e?.target?.value;
    onChangeProp && onChangeProp(e);
    onChangeValue && onChangeValue(text, e);
    onChangeText && onChangeText(text);
  };

  const onKeyUp = e => {
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      onSubmitEditing && onSubmitEditing(e);
    }
    onKeyUpProp?.(e);
  };

  return (
    <StyledInput
      {...props}
      ref={ref}
      overrideStyle={style}
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
    />
  );
};

export default memo(forwardRef(TextInput));
