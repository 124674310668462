import React from 'react';
import {resolveExp} from '@unthinkable/react-utils';
import {Chip, AvatarChip} from '@unthinkable/react-chip';

export const ChipRender = ({
  value,
  colorField = 'color',
  onChipPress,
  onCross,
  styles,
  avatarChip,
  size = 18,
  getDisplayValue,
  resolveColor,
}) => {
  let displayValue = value;
  if (typeof getDisplayValue === 'function') {
    displayValue = getDisplayValue({value});
  }

  let colorValue =
    displayValue && colorField ? resolveExp(value, colorField) : 'Accent 3';
  if (typeof resolveColor === 'function') {
    colorValue = resolveColor(colorValue);
  }
  if (typeof colorValue === 'string') {
    colorValue = {chip: {color: colorValue}};
  }
  let {chip: chipColors, avatar: avatarColors} = colorValue || {};

  let chipRender = (
    <Chip
      styles={styles?.chip}
      value={displayValue}
      onPress={onChipPress}
      onCross={onCross}
      {...chipColors}
    />
  );
  if (avatarChip) {
    chipRender = (
      <AvatarChip
        size={size}
        styles={styles.avatarChip}
        value={displayValue}
        backgroundColor={chipColors?.color}
        avatarBackgroundColor={avatarColors?.color}
        borderColor={chipColors?.borderColor}
        avatarBorderColor={avatarColors?.borderColor}
        textColor={chipColors?.textColor}
        avatarTextColor={avatarColors?.textColor}
        onPress={onChipPress}
        onCross={onCross}
      />
    );
  }
  return chipRender;
};
