import * as React from 'react';

export const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};

export const resolvePropStyle = (style, propName) => {
  let propValue = undefined;

  if (Array.isArray(style)) {
    style = style.map(item => {
      if (item && item.hasOwnProperty(propName)) {
        propValue = item[propName];
        const {[propName]: _, ...rest} = item;
        return rest;
      }
      return item;
    });
  } else if (style && typeof style === 'object') {
    if (style.hasOwnProperty(propName)) {
      propValue = style[propName];
      const {[propName]: _, ...rest} = style;
      style = rest;
    }
  }

  return {
    style,
    [propName]: propValue,
  };
};
