/**
 * todo ref should not always be there, add in case of layout - rohit bansal - 04-12-18
 *
 */
import React, {
  useLayoutEffect,
  forwardRef,
  useCallback,
  useEffect,
} from 'react';
import {styled} from 'styled-components';

const StyledView = styled.div.withConfig({
  shouldForwardProp: prop => {
    return !['styles', 'backgroundImage', 'style', 'activeOpacity'].includes(
      prop,
    );
  },
})`
  align-items: stretch;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  position: relative;
  ${props =>
    props.backgroundImage ? {backgroundImage: `url(${props.backgroundImage})`} : {}}
  ${props => props.style}
`;

const View = forwardRef(
  (
    {
      children,
      onLayout,
      onLongPress,
      onPress,
      onPressIn,
      onPressOut,
      disabled,
      stopPropagation,
      onContextMenu: onContextMenuProp,
      ...props
    },
    ref,
  ) => {
    const _ref = React.useRef(null);

    const measure = callback => {
      if (_ref) {
        const {x, y, width, height} = _ref.current.getBoundingClientRect();
        callback && callback(x, y, width, height, x, y);
      }
    };

    useEffect(() => {
      if (_ref.current) {
        _ref.current.measure = measure;
        if (ref) {
          if (typeof ref === 'function') {
            ref(_ref.current);
          } else {
            try {
              ref.current = _ref.current;
            } catch (error) {
              throw new Error(`Cannot assign value '${_ref}' to ref '${ref}'`);
            }
          }
        }
      }
    }, [_ref, ref]);

    useLayoutEffect(() => {
      if (_ref.current) {
        onLayout &&
          onLayout({
            nativeEvent: {
              layout: _ref.current.getBoundingClientRect(),
            },
          });
      }
    });

    const onContextMenu = useCallback(
      e => {
        e && e.preventDefault && e.preventDefault();
        onLongPress && onLongPress();
        onContextMenuProp && onContextMenuProp(e);
      },
      [onLongPress, onContextMenuProp],
    );

    const onClick = useCallback(
      e => {
        onPress && onPress(e);
        stopPropagation !== false && e.stopPropagation && e.stopPropagation();
      },
      [onPress, stopPropagation],
    );

    let extraProps = {};
    if (!disabled) {
      if (onPress) {
        extraProps.onClick = onClick;
      }
      if (onLongPress) {
        extraProps.onContextMenu = onContextMenu;
      }
      if (onPressIn) {
        extraProps.onPointerDown = onPressIn;
      }
      if (onPressOut) {
        extraProps.onPointerUp = onPressOut;
        extraProps.onPointerLeave = onPressOut;
      }
    }
    return (
      <StyledView ref={_ref} {...props} {...extraProps}>
        {children}
      </StyledView>
    );
  },
);

export default View;
