import React, {useLayoutEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({children, target}) => {
  const [mounted, setMounted] = useState(false);
  const elementRef = useRef();
  useLayoutEffect(() => {
    setMounted(true);
    elementRef.current = target || document.createElement('div');
    if (!target) {
      document.body.appendChild(elementRef.current);
    }
    return () => {
      !target && document.body.removeChild(elementRef.current);
    };
  }, [target]);
  if (!mounted) {
    return null;
  }
  return createPortal(children, elementRef.current);
};

Portal.propTypes = {
  children: PropTypes.node,
  zIndex: PropTypes.number,
  target: PropTypes.element,
};

export default Portal;
