import {useRef, useState} from 'react';
import {hasValue, useMergeRefs} from '@unthinkable/react-utils';

export const useInput = ({
  ref,
  labelPosition = 'floating',
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  placeholder,
  autoFocus,
  caretHidden,
  placeholderTextColor,
  onChange,
  onChangeText,
  onChangeValue,
  onSubmitEditing,
  onKeyUp,
  onKeyPress,
  onKeyDown,
  keyboardType,
  secureTextEntry,
  type,
  inputProps,
  as,
  skipActiveLabelOnFocus,
  ...props
}) => {
  const {disabled, readOnly, editable, label, value, required} = props;

  let [focus, setFocus] = useState();

  const onFocus = e => {
    setFocus(true);
    onFocusProp?.(e);
  };

  const onBlur = e => {
    setFocus(false);
    onBlurProp?.(e);
  };

  const currentRef = useRef();
  const inputRef = useMergeRefs(currentRef, ref);

  let isLabelActive =
    hasValue(
      value === undefined || value === null ? inputRef?.current?.value : value,
    ) ||
    (!readOnly && !skipActiveLabelOnFocus && focus);

  let isFloatingLabel = false;

  if (label && labelPosition === 'floating') {
    isFloatingLabel = true;
    if (!isLabelActive) {
      placeholder = void 0;
    }
  } else if (placeholder && required) {
    placeholder = `${placeholder} *`;
  }

  return {
    ...props,
    focus,
    isLabelActive,
    inputRef,
    labelPosition,
    isFloatingLabel,
    inputProps: {
      type,
      as,
      secureTextEntry,
      value,
      focus,
      onFocus,
      onBlur,
      disabled,
      readOnly,
      editable,
      placeholder,
      autoFocus,
      caretHidden,
      placeholderTextColor,
      onChange,
      onChangeText,
      onChangeValue,
      onSubmitEditing,
      onKeyUp,
      onKeyPress,
      onKeyDown,
      keyboardType,
      isFloatingLabel,
      ...inputProps,
    },
  };
};
