import React from 'react';
import {
  FloatingContainer,
  FloatingText,
  StyledLabel,
  Asterisk,
} from './Styles';
import {View} from '@unthinkable/react-core-components';

export const TopLabel = ({label, required, styles}) => {
  return (
    <StyledLabel styles={styles}>
      {label}
      {required ? <Asterisk>{' * '}</Asterisk> : void 0}
    </StyledLabel>
  );
};

export const FloatingLabel = ({
  styles,
  isLabelActive,
  disabled,
  readOnly,
  label,
  activeLabel,
  required,
  onPress,
}) => {
  return (
    <FloatingContainer
      styles={styles}
      active={isLabelActive}
      disabled={disabled || readOnly}
      onPress={onPress}>
      <FloatingText styles={styles} active={isLabelActive}>
        {`${(isLabelActive && activeLabel) || label}`}
        {required ? <Asterisk>{' * '}</Asterisk> : void 0}
      </FloatingText>
    </FloatingContainer>
  );
};

export const InputLabel = ({
  children,
  labelPosition = 'top',
  inputRef,
  ...props
}) => {
  const {label} = props;
  if (!label) {
    return children || null;
  }
  if (labelPosition == 'top') {
    return (
      <View>
        <TopLabel {...props} />
        {children}
      </View>
    );
  } else if (labelPosition === 'floating') {
    return (
      <View>
        {children}
        <FloatingLabel
          {...props}
          onPress={e => {
            inputRef?.current?.focus?.();
          }}
        />
      </View>
    );
  }
};
