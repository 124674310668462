import React, {forwardRef} from 'react';
import {
  Input,
  InputContainer,
  InputWrapper,
  useInput,
} from '@unthinkable/react-input';

const TextInput = forwardRef((props, ref) => {
  props = useInput({...props, ref});
  const {styles, inputProps, inputRef} = props;
  return (
    <InputWrapper {...props}>
      <InputContainer {...props}>
        <Input {...inputProps} ref={inputRef} styles={styles} />
      </InputContainer>
    </InputWrapper>
  );
});

export default TextInput;
