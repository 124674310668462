import formats from './utils/formats';

const {
  formatDate,
  formatNumber,
  formatCurrency,
  formatIndianCurrency,
  formatPercentage,
} = formats;

export default {
  date: {
    format: formatDate,
    formatOptions: {
      format: 'DD MMM',
    },
  },
  number: {
    format: formatNumber,
  },
  currency: {
    format: formatCurrency,
  },
  indianCurrency: {
    format: formatIndianCurrency,
  },
  percentage: {
    format: formatPercentage,
  },
};
