import React from 'react';
import {InputLabel} from './InputLabel';
import ErrorMessage from './ErrorMessage';
import HelperMessage from './HelperMessage';

export const InputWrapper = ({error, helperText, children, ...props}) => {
  return (
    <InputLabel {...props}>
      {children}
      {error ? <ErrorMessage error={error} styles={props?.styles} /> : void 0}
      {helperText ? (
        <HelperMessage helperText={helperText} styles={props?.styles} />
      ) : (
        void 0
      )}
    </InputLabel>
  );
};
