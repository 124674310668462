import {useEffect, useMemo} from 'react';
import {useEventHandler} from '@unthinkable/react-event-handler';

export const useDataFetchEvents = ({eventSourceId = []}, callback) => {
  const {listen, unlisten} = useEventHandler() || {};

  eventSourceId = useMemo(() => {
    if (typeof eventSourceId === 'string') {
      return [eventSourceId];
    }
    return eventSourceId;
  }, [
    typeof eventSourceId === 'string'
      ? eventSourceId
      : JSON.stringify(eventSourceId),
  ]);

  useEffect(() => {
    const onInsertEvent = ({params: {id}}) => {
      if (eventSourceId?.some(doc => doc === id)) {
        callback();
      }
    };
    const onUpdateEvent = ({params: {id}}) => {
      if (eventSourceId?.some(doc => doc === id)) {
        callback();
      }
    };
    const onDeleteEvent = ({params: {id}}) => {
      if (eventSourceId?.some(doc => doc === id)) {
        callback();
      }
    };

    listen(`dataInserted`, onInsertEvent);
    listen(`dataUpdated`, onUpdateEvent);
    listen(`dataDeleted`, onDeleteEvent);

    return () => {
      unlisten(`dataInserted`, onInsertEvent);
      unlisten(`dataUpdated`, onUpdateEvent);
      unlisten(`dataDeleted`, onDeleteEvent);
    };
  }, [callback, eventSourceId, listen, unlisten]);
};
