import {
  useResponsiveBreakpoint,
  breakpointWidths,
} from '@unthinkable/react-utils';

const responsiveColumnsSetting = {
  sm: {
    excludeColumns: true,
    primary: true,
    defaultColumnsCount: 1,
  },
};

const resolveColumnsRecursive = (columns, currentBreakpoint) => {
  const columnsSetting = responsiveColumnsSetting[currentBreakpoint];
  if (!columns?.length) {
    return [];
  }
  let resolvedColumns = columns.reduce((acc, column) => {
    const {responsive} = column;
    if (responsive) {
      if (
        (typeof responsive === 'string' &&
          breakpointWidths[currentBreakpoint] >=
            breakpointWidths[responsive]) ||
        (Array.isArray(responsive) && responsive.includes(currentBreakpoint))
      ) {
        if (column.children?.length) {
          const visibleChildren = resolveColumnsRecursive(
            column.children,
            currentBreakpoint,
          );
          if (visibleChildren.length > 0) {
            acc.push({
              ...column,
              children: visibleChildren,
            });
          }
        } else {
          acc.push(column);
        }
      }
    } else {
      if (
        !columnsSetting?.excludeColumns ||
        (columnsSetting?.primary && column.primary)
      ) {
        if (column.children?.length) {
          const visibleChildren = resolveColumnsRecursive(
            column.children,
            currentBreakpoint,
          );
          if (visibleChildren.length > 0) {
            acc.push({
              ...column,
              children: visibleChildren,
            });
          }
        } else {
          acc.push(column);
        }
      }
    }
    return acc;
  }, []);

  if (!resolvedColumns?.length && columnsSetting?.defaultColumnsCount) {
    resolvedColumns = columns.slice(0, columnsSetting?.defaultColumnsCount);
  }
  return resolvedColumns;
};

export const useResponsiveColumns = columns => {
  const currentBreakpoint = useResponsiveBreakpoint();
  if (!columns?.length) return columns;
  return resolveColumnsRecursive(columns, currentBreakpoint);
};
