import React from 'react';
import {Image, Row, TouchableOpacity} from '@unthinkable/react-core-components';
import Upload from '../images/Upload.svg';
import CrossBlue from '../images/CrossBlue.svg';
import {
  AddAttachmentContainer,
  AddAttachmentText,
  AddAttachmentTextContainer,
} from './Styles';

export const AddAttachment = ({
  styles,
  error,
  uploadIcon,
  crossIcon,
  uploadText,
  numberOfLines = 1,
  gap = 8,
  inlineImageComponent,
  value,
  disabled,
  readOnly,
  onRemove,
  inline,
  onChangeValue,
  ...props
}) => {
  return (
    <AddAttachmentContainer styles={styles} error={error}>
      <Row style={{alignItems: 'center'}} gap={gap}>
        <Image source={uploadIcon || Upload} />
        {inline && inlineImageComponent && value?.length ? (
          <Row gap={gap} style={{alignItems: 'center'}}>
            {inlineImageComponent({
              ...props,
              value: value,
              onRemove: disabled || readOnly ? void 0 : onRemove,
            })}
            <TouchableOpacity
              onPress={() => {
                onChangeValue(null);
              }}>
              <Image source={crossIcon || CrossBlue} />
            </TouchableOpacity>
          </Row>
        ) : uploadText ? (
          <AddAttachmentTextContainer>
            <AddAttachmentText numberOfLines={numberOfLines} styles={styles}>
              {uploadText}
            </AddAttachmentText>
          </AddAttachmentTextContainer>
        ) : (
          void 0
        )}
      </Row>
    </AddAttachmentContainer>
  );
};
