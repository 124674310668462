import React, {useContext} from 'react';

const ReactEventHandlerContext = React.createContext();

export class EventHandlerProvider extends React.Component {
  eventListeners = {};
  notify = (event, params) => {
    let listeners = this.eventListeners[event];
    let resp;
    if (listeners) {
      listeners.forEach(listener => {
        listener({params});
      });
    }
    return resp;
  };

  listen = (event, callback) => {
    let listeners = this.eventListeners[event] || [];
    this.eventListeners[event] = [...listeners, callback];
  };

  unlisten = (event, callback) => {
    let listeners = this.eventListeners[event];
    if (listeners && listeners.length) {
      this.eventListeners[event] = listeners.filter(
        _callback => _callback !== callback,
      );
    }
  };

  reactEventDispatcher = {
    notify: this.notify,
    listen: this.listen,
    unlisten: this.unlisten,
  };
  render() {
    return (
      <ReactEventHandlerContext.Provider value={this.reactEventDispatcher}>
        {this.props.children}
      </ReactEventHandlerContext.Provider>
    );
  }
}

export const useEventHandler = () => {
  let context = useContext(ReactEventHandlerContext);
  return context;
};
