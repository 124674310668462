import { getTransitionStyles } from "./getTransitionStyle";
import { useTransition } from "./useTransition";
import { transitions } from "./transitions";
import PropTypes from "prop-types";

export const AVAILABLE_TRANSITIONS = Object.keys(transitions);

const Transition = ({
  transition,
  duration = 250,
  mounted,
  children,
  timingFunction,
  onExit,
  onEntered,
  onEnter,
  onExited,
}) => {
  const {
    transitionDuration = 1000,
    transitionStatus,
    transitionTimingFunction,
  } = useTransition({
    mounted,
    duration,
    timingFunction,
    onExit,
    onEntered,
    onEnter,
    onExited,
  });
  if (transitionDuration === 0) {
    return mounted ? children({}) : null;
  }
  return transitionStatus === "exited"
    ? null
    : children(
        getTransitionStyles({
          transition,
          duration: transitionDuration,
          state: transitionStatus,
          timingFunction: transitionTimingFunction,
        })
      );
};

Transition.propTypes = {
  transition: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  duration: PropTypes.number,
  mounted: PropTypes.bool,
  children: PropTypes.func, //that return Element
  timingFunction: PropTypes.string,
  onExit: PropTypes.func,
  onEntered: PropTypes.func,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default Transition;
