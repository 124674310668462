import React, {useRef, useCallback} from 'react';
import {Platform, TouchableOpacity} from '@unthinkable/react-core-components';
import {useUploadFiles} from './hooks/useUploadFiles';
import {AddAttachment} from './AddAttachment';
import {MultipleFileRender} from './MultipleFileRender';
import {PositionActivityIndicator} from '@unthinkable/react-activity-indicator';
import {FileInputWrapper} from './Styles';
import {FileInputCell} from './WebStyles';
import {InputWrapper} from '@unthinkable/react-input';

const FileInput = ({
  onFocus,
  onBlur,
  onChangeValue,
  multiple,
  upload,
  options,
  value,
  onlyImage,
  valueStyle,
  placeholder,
  skipInput,
  skipValue,
  disabled,
  readOnly,
  error,
  inline,
  inlineImageComponent,
  inputProps = {},
  ...props
}) => {
  const {styles} = props;
  const inputRef = useRef();

  const onUploadFile = useCallback(
    (values, e) => {
      let newValue = void 0;
      if (values?.length) {
        if (multiple) {
          newValue = value ? [...value, ...values] : values;
        } else {
          newValue = values[0];
        }
        onChangeValue && onChangeValue(newValue);
      }
      setTimeout(_ => {
        onBlur && onBlur(e);
      });
    },
    [value, multiple, onChangeValue, onBlur],
  );

  let {uploadFiles, uploading} = useUploadFiles({
    upload,
    multiple,
    options,
    onUploadFile,
  });
  const onClick = useCallback(
    e => {
      if (uploading) {
        return;
      }
      inputRef?.current?.click();
      onFocus && onFocus(e);
    },
    [inputRef, onFocus, uploading],
  );

  const onRemove = useCallback(
    ({index}) => {
      let newValue = null;
      if (multiple) {
        newValue = [...value];
        newValue.splice(index, 1);
      }
      onChangeValue && onChangeValue(newValue);
    },
    [value, multiple, onChangeValue],
  );

  const onChangeValueCallback = useCallback(e => {
    const upload = async () => {
      e.preventDefault();
      let files = e.dataTransfer
        ? e.dataTransfer.files
        : e.target && e.target.files;
      let fileValues = files ? Object.values(files) : [];
      await uploadFiles(fileValues, e);
      if (Platform.OS === 'web') {
        e.target.value = null;
      }
    };
    upload();
  });

  if (onlyImage && !inputProps?.accept) {
    inputProps = inputProps ? {...inputProps} : {};
    inputProps.accept = 'image/*';
  }

  if (value && !Array.isArray(value)) {
    value = [value];
  }

  let AddAttachmentRender = (
    <TouchableOpacity disabled={disabled || readOnly} onClick={onClick}>
      <FileInputCell
        {...inputProps}
        styles={styles}
        type="file"
        multiple={multiple}
        ref={inputRef}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChangeValueCallback}
      />
      <AddAttachment
        error={error}
        uploadText={placeholder || 'Upload Attachment'}
        uploading={uploading}
        inlineImageComponent={inlineImageComponent}
        inline={inline}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        onRemove={onRemove}
        onChangeValue={onChangeValue}
        {...props}
        styles={styles?.input}
      />
      {uploading ? <PositionActivityIndicator size="small" /> : void 0}
    </TouchableOpacity>
  );

  return (
    <InputWrapper {...props}>
      <FileInputWrapper>
        {skipInput ? void 0 : AddAttachmentRender}
        {inline || skipValue || !value?.length ? (
          void 0
        ) : (
          <MultipleFileRender
            styles={styles}
            {...props}
            onRemove={disabled || readOnly ? void 0 : onRemove}
            values={value}
          />
        )}
      </FileInputWrapper>
    </InputWrapper>
  );
};

export default FileInput;
