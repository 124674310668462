import React from 'react';
import {AvatarContainer, AvatarText} from './Styles';

const resolveAvatarValue = value => {
  if (!value || typeof value !== 'string') {
    return '';
  }
  value = value.split(' ');
  let avatarValue = '';
  if (value.length) {
    for (let key of value) {
      avatarValue += key.substring(0, 1);
      if (avatarValue.length === 2) {
        break;
      }
    }
  }
  return avatarValue.toUpperCase();
};

const Avatar = props => {
  let {
    styles,
    children,
    title,
    value,
    // defaultIcon,
    // defaultText,
    size,
    fontSize,
    lineHeight,
    variant,
    color,
    borderColor,
    textColor,
    borderWidth,
    containerStyle,
  } = props;

  // if (icon === undefined && value === undefined) {
  //   icon = defaultIcon;
  //   value = defaultText;
  // }
  if (value === undefined || value === null) {
    return null;
  }
  return (
    <AvatarContainer
      styles={styles}
      title={title || value}
      size={size}
      containerStyle={containerStyle}
      backgroundColor={color}
      borderColor={borderColor}
      borderWidth={borderWidth}
      variant={variant}>
      {children || (
        <AvatarText
          styles={styles}
          color={textColor}
          size={size}
          fontSize={fontSize}
          lineHeight={lineHeight}>
          {resolveAvatarValue(value)}
        </AvatarText>
      )}
    </AvatarContainer>
  );
};

export default Avatar;

// const source = typeof icon === 'string' ? {uri: icon} : icon;

// let imageStyle = {};
// if (Platform.OS !== 'web' && source?.uri) {
//   iconStyle = {width: '100%', height: '100%', ...iconStyle};
//   resizeMode = 'contain';
//   style = {overflow: 'hidden', ...style};
// }

// <Image
//   title={title}
//   style={{
//     maxHeight: style.height,
//     maxWidth: style.width,
//     borderRadius: style.borderRadius,
//     overflow: 'hidden',
//     ...iconStyle,
//   }}
//   alt={false}
//   source={source}
//   resizeMode={resizeMode}
// />;
