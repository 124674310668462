import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {View, Text, Styled} from '@unthinkable/react-core-components';

const ToastContext = React.createContext();

let showToast = null;

export const toast = props => {
  if (showToast) {
    showToast(props);
  }
};

const useToastState = () => {
  let [toast, setState] = useState({});
  let timerRef = useRef();

  const hideToast = useCallback(() => {
    let newState = {
      ...toast,
      message: void 0,
      type: void 0,
      time: void 0,
      visible: false,
    };
    setState(newState);
  }, [toast]);

  const show = useCallback(
    ({message, type = 'Success', time = 2000}) => {
      let newState = {...toast, message, type, time, visible: true};
      setState(newState);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(hideToast, time);
    },
    [toast, timerRef],
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  return {toast, show};
};
export const ToastProvider = ({children}) => {
  let {toast, show} = useToastState();
  let value = useMemo(() => {
    return {toast, show};
  }, [toast, show]);
  showToast = show;
  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

const Container = Styled(View)`
position: absolute;
left: 0px;
right: 0px;
bottom: 30px;
justify-content: center;
align-items: center;
z-index: 1000;
${({styles}) => styles?.containerStyle}`;

const InnerContainer = Styled(View)`
    background-color: #34C759;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
${({styles}) => styles?.textContainerStyle}
`;

const StyledText = Styled(Text)`
color: #F0FFF4;
font-size: 12px;
font-family: Inter-Medium;
line-height: 16px;
${({styles}) => styles?.textStyle}`;

const Toast = ({styles, toast}) => {
  const {message} = toast;
  return (
    <Container styles={styles}>
      <InnerContainer styles={styles}>
        <StyledText styles={styles}>{message}</StyledText>
      </InnerContainer>
    </Container>
  );
};

export const useToast = () => {
  let {show} = useContext(ToastContext);
  return show;
};
export const ToastComponent = ({styles}) => {
  // toast = {
  //   visible: true,
  //   type: 'Error',
  //   message: 'posting...',
  // };

  let {toast} = useContext(ToastContext);
  const {visible, message, type = 'Success'} = toast;
  if (!visible || !message) {
    return null;
  }

  if (type === 'Success') {
    return <Toast styles={styles?.success} toast={toast} />;
  } else if (type === 'Error') {
    return <Toast styles={styles?.error} toast={toast} />;
  } else {
    return null;
  }
};
