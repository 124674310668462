import {useEffect, useRef} from 'react';
import {useField as useFieldFormik} from 'formik';
import {useFormContext} from '../FormContext';
import {hasValue} from '@unthinkable/react-utils';
import lodash from 'lodash';

const useValidateField = ({
  field,
  label,
  required,
  validate,
  minLength,
  maxLength,
}) => {
  const formContext = useFormContext();
  const {errors, isSubmitting, setFieldError} = formContext;
  const prevFieldValue = useRef(void 0);
  const mounted = useRef(false);

  const validateField = fieldValue => {
    const isValueChanged = !lodash.isEqual(prevFieldValue.current, fieldValue);
    prevFieldValue.current = fieldValue;
    if (required && !hasValue(fieldValue)) {
      return required.message || `${label || field} is required`;
    }
    if (minLength && fieldValue?.length < minLength) {
      return `value length is lesser then ${minLength}`;
    }
    if (maxLength && fieldValue?.length > maxLength) {
      return `value is greater then ${maxLength}`;
    }
    if (validate) {
      if (!mounted.current || isSubmitting || isValueChanged) {
        return validate(fieldValue, formContext);
      } else {
        // return the error on change of other fields
        return errors[field];
      }
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      setTimeout(_ => {
        formContext.validateField(field);
        mounted.current = true;
      }, 50);
    }
    return () => {
      setTimeout(_ => {
        setFieldError(field, void 0);
      }, 50);
    };
  }, []);

  return {
    validateField,
  };
};

export const useField = ({
  type,
  shouldValidate,
  autoSave,
  dependentFields,
  validate,
  ...props
}) => {
  const formContext = useFormContext();
  const {
    fieldTypes,
    readOnly,
    hideInputError,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = formContext;
  const {validate: fieldTypeValidate, ...fieldType} = fieldTypes?.[type] || {};
  const {field, addOnFields} = props;

  if (!validate && fieldTypeValidate) {
    validate = fieldTypeValidate;
  }

  const {validateField} = useValidateField({...props, validate});

  const [{onChange, onBlur: onBlurFormik, ...formikFieldProps}, meta, helpers] =
    useFieldFormik({
      name: field,
      validate: validateField,
    });

  const formProps = {...formikFieldProps, meta, ...helpers, ...formContext};

  const onBlur = e => {
    e?.persist?.();
    setTimeout(() => {
      // touched not found before setState in formik and event handling done only for react not for react-native
      onBlurFormik?.(field)?.(e);
    }, 0);
    props.onBlur?.(e);
    autoSave && handleSubmit?.();
  };

  const onChangeValue = (value, e, additionalValues) => {
    setFieldValue(field, value, shouldValidate);
    if (addOnFields?.length) {
      addOnFields.forEach(addOnField => {
        if (addOnField?.field) {
          setFieldValue(
            addOnField.field,
            additionalValues?.[addOnField.valueField],
          );
        }
      });
    }
    props.onChangeValue?.(value, e, {...formProps, additionalValues});
    if (dependentFields?.length) {
      dependentFields.forEach(dependentField => {
        setFieldValue(dependentField, void 0);
      });
    }
  };

  useEffect(() => {
    if (isSubmitting && !meta.touched) {
      helpers.setTouched(true);
    }
  }, [isSubmitting]);

  return [
    {
      hideError: hideInputError,
      ...fieldType,
      ...props,
      ...formikFieldProps,
      readOnly: readOnly || props.readOnly,
      onBlur,
      onChangeValue,
    },
    meta,
    helpers,
    formContext,
  ];
};
