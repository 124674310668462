import { getTransitionStyles } from "./getTransitionStyle";
import { useTransition } from "./useTransition";
import PropTypes from "prop-types";

const GroupedTransition = ({
  transitions,
  duration = 250,
  mounted,
  children,
  timingFunction,
  onExit,
  onEntered,
  onEnter,
  onExited,
}) => {
  const { transitionDuration, transitionStatus, transitionTimingFunction } = useTransition({
    mounted,
    duration,
    timingFunction,
    onExit,
    onEntered,
    onEnter,
    onExited,
  });
  if (transitionDuration === 0) {
    return mounted ? children({}) : null;
  }
  if (transitionStatus === "exited") {
    return null;
  }
  const transitionsStyles = Object.keys(transitions).reduce((acc, transition) => {
    acc[transition] = getTransitionStyles({
      duration: transitions[transition].duration,
      transition: transitions[transition].transition,
      timingFunction: transitions[transition].timingFunction || transitionTimingFunction,
      state: transitionStatus,
    });
    return acc;
  }, {});
  return children(transitionsStyles);
};

GroupedTransition.propTypes = {
  transitions: PropTypes.objectOf(
    PropTypes.shape({
      transition: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      timingFunction: PropTypes.string,
      duration: PropTypes.number,
    })
  ),
  duration: PropTypes.number,
  mounted: PropTypes.bool,
  children: PropTypes.func, //that return Element
  timingFunction: PropTypes.string,
  onExit: PropTypes.func,
  onEntered: PropTypes.func,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};
export default GroupedTransition;
