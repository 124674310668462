export const sortArrayObjects = (array, sortOrder, sortField) => {
  if (!sortField) {
    return array;
  }
  return array.sort(function (a, b) {
    return sortOrder === 'desc'
      ? b[sortField] - a[sortField]
      : a[sortField] - b[sortField];
  });
};
