import React, {useRef} from 'react';
import {TouchableOpacity} from '@unthinkable/react-core-components';
import {useControllableState} from '@unthinkable/react-utils';
import {ResponsiveModal} from './ResponsiveModal';

const WithModal = ({
  children,
  renderModal: renderModalProp,
  hideModal: hideModalProp,
  disabled,
  mounted,
  onChange,
  ...props
}) => {
  const _ref = useRef(null);
  const [visible, setVisible] = useControllableState({
    value: mounted,
    defaultValue: false,
    onChange,
  });

  const hideModal = e => {
    setVisible(false);
    hideModalProp?.(e);
  };

  return (
    <>
      <TouchableOpacity
        ref={_ref}
        disabled={disabled}
        onPress={() => setVisible(m => !m)}>
        {children}
      </TouchableOpacity>
      <ResponsiveModal
        referenceElement={_ref.current}
        isVisible={visible}
        hideModal={hideModal}
        {...props}>
        {renderModalProp}
      </ResponsiveModal>
    </>
  );
};

export default WithModal;
