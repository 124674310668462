import React from 'react';
import {
  ContentContainer,
  Icon,
  LoaderAbsoluteContainer,
  StyledIconButton,
} from './Styles';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';

const IconButton = props => {
  const {
    styles,
    onPress,
    icon,
    title,
    children,
    disabled,
    loading = false,
    renderLoader,
  } = props;

  const iconComponent = icon ? <Icon source={icon} styles={styles} /> : void 0;

  return (
    <StyledIconButton
      styles={styles}
      disabled={disabled || loading}
      onPress={onPress}
      title={title}>
      <ContentContainer loading={loading}>
        {children || iconComponent}
      </ContentContainer>
      {loading ? (
        <LoaderAbsoluteContainer>
          {renderLoader ? renderLoader(props) : <ActivityIndicator />}
        </LoaderAbsoluteContainer>
      ) : (
        void 0
      )}
    </StyledIconButton>
  );
};

export default IconButton;
