import React, {forwardRef} from 'react';
import ScrollView from '../ScrollView';
import View from '../View';
import Wrap from '../Wrap';

const FlatList = forwardRef(
  (
    {
      style,
      data = [],
      numColumns,
      columnWrapperStyle,
      renderItem,
      keyExtractor,
      ListHeaderComponent,
      ListFooterComponent,
      renderNewItem,
      onScroll,
      onEndReachedThreshold = 1,
      onEndReached,
      extraData,
      inverted,
      ...props
    },
    ref,
  ) => {
    if (!renderItem) {
      return null;
    }

    const handleScroll = e => {
      onScroll && onScroll(e);
      const {scrollTop, scrollHeight, clientHeight} = e;
      let position = (scrollTop + clientHeight) / scrollHeight;
      if (position >= onEndReachedThreshold) {
        onEndReached && onEndReached();
      }
    };

    let renderData = data?.map((item, index) => {
      let key = keyExtractor ? keyExtractor(item, index) : index;
      return (
        <View
          key={key}
          style={{
            ...(numColumns ? {width: `${100 / numColumns}%`} : {}),
          }}>
          {renderItem({item, index})}
        </View>
      );
    });
    if (numColumns) {
      renderData = <Wrap>{renderData}</Wrap>;
    }

    return (
      <ScrollView
        {...props}
        ref={ref}
        style={{
          ...style,
          ...(inverted ? {flexDirection: 'column-reverse'} : {}),
        }}
        onScroll={handleScroll}>
        {ListHeaderComponent && typeof ListHeaderComponent === 'function'
          ? ListHeaderComponent()
          : null}
        {renderData}
        {renderNewItem && renderNewItem()}
        {ListFooterComponent && typeof ListFooterComponent === 'function'
          ? ListFooterComponent()
          : null}
      </ScrollView>
    );
  },
);

export default FlatList;
