import {useState} from 'react';

export const useControllableState = ({value, defaultValue, onChange}) => {
  const [state, setState] = useState(defaultValue);
  const isControlled = value !== undefined;
  const stateValue = isControlled ? value : state;
  const setStateValue = v => {
    if (!isControlled) {
      setState(v);
    }
    onChange && onChange(v);
  };

  return [stateValue, setStateValue];
};
