import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

const PasswordRender = ({value, ...props}) => {
  if (value?.length) {
    value = Array(value.length).fill('*').join('');
  }
  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} value={value} />
    </InputLabel>
  );
};

export default PasswordRender;
