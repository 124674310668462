import React, {memo} from 'react';
import {Text} from '@unthinkable/react-core-components';
import {useField} from './hooks';

const Field = props => {
  const [fieldInfo, meta, helpers, formContext] = useField(props);
  const {touched, error} = meta || {};

  let {
    render,
    Editor,
    Renderer,
    hideError,
    editorAsRenderer,
    ...restFieldInfo
  } = fieldInfo;

  let errorMessage = touched && !hideError ? error : void 0;
  let Component =
    (!editorAsRenderer && restFieldInfo?.readOnly && Renderer) || Editor;

  return render ? (
    render({...restFieldInfo, error: errorMessage}, formContext)
  ) : Component ? (
    <Component {...restFieldInfo} error={errorMessage} form={formContext} />
  ) : (
    <Text> No Editors found</Text>
  );
};

export default memo(Field);
