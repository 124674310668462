import React from 'react';
import {Linking} from '@unthinkable/react-core-components';
import {
  FileRemoveInnerContainer,
  FileRemoveText,
  FileRemoveWrapper,
  FileRenderImage,
  FileRenderImageContainer,
  FileRenderText,
  FileRenderWrapper,
} from './Styles';

const RemoveFile = ({styles, onRemove}) => {
  if (!onRemove) {
    return null;
  }
  return (
    <FileRemoveWrapper styles={styles} onPress={onRemove}>
      <FileRemoveInnerContainer styles={styles}>
        <FileRemoveText styles={styles}>X</FileRemoveText>
      </FileRemoveInnerContainer>
    </FileRemoveWrapper>
  );
};

export const FileRender = ({
  styles,
  value,
  resourceUrl,
  onPress,
  openInNewTab,
  selected,
  defaultContentTypeIcons,
  numberOfLines = 1,
  ...props
}) => {
  if (!value) {
    return null;
  }
  const {filename, contentType} = value;
  const url = resourceUrl && resourceUrl(value);
  let renderComponent = void 0;
  let showText = false;
  if (contentType?.startsWith('image/') && !contentType?.endsWith('/svg+xml')) {
    renderComponent = (
      <FileRenderImageContainer>
        <FileRenderImage resizeMode={'contain'} source={url} />
      </FileRenderImageContainer>
    );
  } else {
    showText = true;
    const isApplicationType = contentType?.startsWith('application/');
    const isVideoType = contentType?.startsWith('video/');
    const isSVGType = contentType?.endsWith('/svg+xml');
    let contentTypeIcon = isApplicationType
      ? defaultContentTypeIcons?.application
      : isVideoType
      ? defaultContentTypeIcons?.video
      : isSVGType
      ? defaultContentTypeIcons?.image
      : void 0;

    renderComponent = (
      <FileRenderText styles={styles} numberOfLines={numberOfLines}>
        {filename}
      </FileRenderText>
    );

    if (contentTypeIcon) {
      renderComponent = (
        <>
          <FileRenderImageContainer>
            <FileRenderImage resizeMode={'contain'} source={contentTypeIcon} />
          </FileRenderImageContainer>
          {renderComponent}
        </>
      );
    }
  }
  return (
    <FileRenderWrapper
      title={filename}
      selected={selected}
      showText={showText}
      styles={styles}
      onPress={() => {
        onPress && onPress();
        url && openInNewTab !== false && Linking.openURL(url, '_blank');
      }}>
      {renderComponent}
      <RemoveFile styles={styles} {...props} />
    </FileRenderWrapper>
  );
};
