import {
  useResponsiveBreakpoint,
  breakpointWidths,
} from '@unthinkable/react-utils';

export const useResponsiveProps = ({responsiveMode, ...props} = {}) => {
  const currentBreakpoint = useResponsiveBreakpoint();
  if (!responsiveMode || Object.keys(responsiveMode).length === 0) return props;

  Object.keys(responsiveMode).forEach(key => {
    const curr = responsiveMode[key];
    if (
      (typeof curr === 'string' &&
        breakpointWidths[currentBreakpoint] < breakpointWidths[curr]) ||
      (Array.isArray(curr) && curr.includes(currentBreakpoint) === false)
    ) {
      delete props[key];
    }
  });
  return props;
};
