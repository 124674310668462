import React from 'react';
import {
  Row,
  Styled,
  Text,
  TouchableOpacity,
  View,
  Wrap,
} from '@unthinkable/react-core-components';

export const RadioWrapper = Styled(Row)`align-items:center`;

export const RadioText = Styled(Text)`
font-size: 14px;
font-family: Inter-Regular;
line-height: 20px;
color: #19191A;
${({styles}) => styles?.label}
${props => (props.colorValue ? {color: props?.colorValue} : void 0)}
`;

export const RadioContainer = Styled(TouchableOpacity)`
border-radius: 100px;
width: 16px;
height: 16px;
border-width: 1px;
border-color: #7DCD28;
justify-content: center;
align-items: center;
${({styles}) => styles?.container}
${props => (props.colorValue ? {borderColor: props?.colorValue} : void 0)}
`;

export const RadioInnerContainer = Styled(View)`
border-radius: 100px;
width: 10px;
height: 10px;
background-color: #7DCD28;
${({styles}) => styles?.innerContainer}
${props => (props.colorValue ? {backgroundColor: props?.colorValue} : void 0)}
`;

export const RadioGroupContainer = Styled(Wrap)`
column-gap: 36px;
row-gap: 8px;
${({styles}) => styles?.container}
`;
