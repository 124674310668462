import React, {forwardRef} from 'react';
import {useAutoComplete} from './hooks/useAutoComplete';
import BaseAutoComplete from './BaseAutocomplete';

const AutoComplete = (props, ref) => {
  props = useAutoComplete({...props, ref});
  return <BaseAutoComplete {...props} />;
};

export default forwardRef(AutoComplete);
