import React from 'react';
import {
  CreateIcon,
  CreateIconContainer,
  SelectorIcon,
  SelectorContainer,
  CrossIconContainer,
  CrossIconImage,
} from './Styles';

export const AddNewAction = ({styles, onCreate, ...props}) => {
  return (
    <CreateIconContainer
      styles={styles}
      onMouseDown={e => {
        e.preventDefault();
      }}
      onPress={e => {
        onCreate?.(props, e);
      }}>
      <CreateIcon styles={styles} />
    </CreateIconContainer>
  );
};

export const Selector = ({styles, onPress}) => {
  return (
    <SelectorContainer styles={styles} onPress={onPress}>
      <SelectorIcon styles={styles} />
    </SelectorContainer>
  );
};

export const ClearInputAction = ({styles, onPress}) => {
  return (
    <CrossIconContainer styles={styles} onPress={onPress}>
      <CrossIconImage styles={styles} />
    </CrossIconContainer>
  );
};
