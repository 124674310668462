import {Styled, View} from '@unthinkable/react-core-components';

export const StyledPopperContainer = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['width', 'height', 'borderRadius'].includes(prop),
})`
  background-color: #FFFFFF;
  overflow:hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  ${({width, height, borderRadius = 8}) => {
    return {
      width,
      height,
      borderRadius,
    };
  }}   
  ${props => props.styles?.container}
`;
