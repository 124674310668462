import React, {forwardRef, useCallback} from 'react';
import View from '../View';
import Styled from '../styled-components';
import './ScrollView.css';

const StyledView = Styled(View).withConfig({
  shouldForwardProp: prop =>
    ![
      'overrideStyle',
      'styles',
      'contentContainerStyle',
      'flex',
      'bounces',
      'horizontal',
      'removeClippedSubviews',
      'keyboardShouldPersistTaps',
    ].includes(prop),
})`
  flex: ${({flex}) => (flex === false || flex === 0 ? void 0 : flex || 1)};
  overflow: auto;
  ${({overrideStyle}) => overrideStyle}
  ${({contentContainerStyle}) => contentContainerStyle}
  ${({horizontal}) => {
    if (horizontal) {
      return {flexDirection: 'row'};
    } else if (horizontal === false) {
      return {
        overflowX: 'hidden',
      };
    }
  }}
`;

const ScrollView = forwardRef(
  (
    {
      showsVerticalScrollIndicator = true,
      showsHorizontalScrollIndicator = true,
      className = '',
      onScroll: onScrollProps,
      style,
      ...props
    },
    ref,
  ) => {
    const onScroll = useCallback(
      e => {
        let eventValues = e.target;
        onScrollProps && onScrollProps(eventValues);
      },
      [onScrollProps],
    );

    // This is for hide-vertical-scroll-indicator we use css class for hide it and add to className and Pass
    if (!showsVerticalScrollIndicator) {
      className += ' hide-vertical-scroll-indicator';
    }
    // This is for hide-horizontal-scroll-indicator
    if (!showsHorizontalScrollIndicator) {
      className += ' hide-horizontal-scroll-indicator';
    }
    return (
      <StyledView
        {...props}
        overrideStyle={style}
        ref={ref}
        className={className}
        onScroll={onScroll}
      />
    );
  },
);

export default ScrollView;
