import React from 'react';
import ChipCross from '../images/ChipCross.svg';
import {View} from '@unthinkable/react-core-components';
import {Avatar} from '@unthinkable/react-avatar';
import {
  AvatarChipContainer,
  AvatarChipText,
  ChipCrossIcon,
  ChipCrossIconContainer,
} from './Styles';

const AvatarChip = ({
  styles,
  value,
  title,
  avatar,
  numberOfLines = 1,
  gap = 8,
  maxWidth,
  borderWidth,
  borderRadius,
  backgroundColor, // background color of the chip
  avatarBackgroundColor, // background color of avatar
  borderColor, // border color of chip
  avatarBorderColor, // border color of avatar
  textColor, // text color of chip
  avatarTextColor, // text color of avatar
  onCross,
  crossable,
  ...props
}) => {
  if (!value && avatar?.value) {
    value = avatar.value;
  }
  if (!avatar) {
    avatar = {value};
  }

  return (
    <View style={{alignItems: 'flex-start'}}>
      <AvatarChipContainer
        gap={gap}
        crossable={crossable}
        onCross={onCross}
        styles={styles}
        maxWidth={maxWidth}
        borderColor={borderColor}
        borderWidth={borderWidth}
        borderRadius={borderRadius}
        backgroundColor={backgroundColor}
        title={title || value}>
        {React.isValidElement(avatar) ? (
          avatar
        ) : (
          <Avatar
            styles={styles?.avatar}
            color={avatarBackgroundColor}
            borderColor={avatarBorderColor}
            textColor={avatarTextColor}
            {...avatar}
            {...props}
          />
        )}
        <View style={{flex: 1, overflow: 'hidden'}}>
          <AvatarChipText
            styles={styles}
            color={textColor}
            size={props?.size}
            numberOfLines={numberOfLines}>
            {value}
          </AvatarChipText>
        </View>
        {onCross && crossable !== false ? (
          <ChipCrossIconContainer
            styles={styles}
            onPress={() => {
              onCross();
            }}>
            <ChipCrossIcon
              styles={styles}
              source={styles?.crossIcon || ChipCross}
            />
          </ChipCrossIconContainer>
        ) : (
          void 0
        )}
      </AvatarChipContainer>
    </View>
  );
};

export default AvatarChip;
