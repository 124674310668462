import {useState, useEffect} from 'react';
import {Dimensions, Platform} from '@unthinkable/react-core-components';
import {breakpointWidths, getCurrentBreakpoint} from '../util/Breakpoint';

export const useResponsiveBreakpoint = breakpoints => {
  const windowWidth = Dimensions.get('window').width;

  const [brkPnt, setBrkPnt] = useState(() =>
    getCurrentBreakpoint(windowWidth, breakpoints),
  );

  useEffect(() => {
    const updateSize = () => {
      const windowWidth = Dimensions.get('window').width;

      setBrkPnt(getCurrentBreakpoint(windowWidth, breakpoints));
    };
    if (Platform.OS === 'web') {
      window.addEventListener('resize', updateSize);
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', updateSize);
      }
    };
  }, [breakpoints]);

  return brkPnt;
};

export const useResolveBreakpoint = ({
  breakpoints = breakpointWidths,
  breakpointObj,
}) => {
  const currentBreakpoint = useResponsiveBreakpoint(breakpoints);

  if (breakpointObj[currentBreakpoint]) {
    return breakpointObj[currentBreakpoint];
  } else {
    const breakpointKeys = Object.keys(breakpointObj);
    const sortedKeys = breakpointKeys.sort(
      (a, b) => breakpoints[a] - breakpoints[b],
    );
    const index = sortedKeys.findIndex(
      breakpoint => breakpoints[breakpoint] > breakpoints[currentBreakpoint],
    );

    if (index === -1) {
      // If no breakpoints are larger, return the largest available one
      return breakpointObj[sortedKeys[sortedKeys.length - 1]];
    } else if (index === 0) {
      // If the current breakpoint is smaller than the smallest available one, return the smallest one
      return breakpointObj[sortedKeys[0]];
    } else {
      // Otherwise, return the next smallest available breakpoint
      return breakpointObj[sortedKeys[index - 1]];
    }
  }
};
