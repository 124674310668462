import {useEffect, useRef} from 'react';
import lodash from 'lodash';

const separateComputeAndDependencies = computation => {
  let compute = void 0;
  let dependencies = [];
  let condition = void 0;
  let multi = false;

  if (typeof computation === 'function') {
    compute = computation;
  } else if (Array.isArray(computation)) {
    compute = computation[0];
    dependencies = computation[1];
    condition = computation[2];
    multi = computation[3];
  } else if (typeof computation === 'object') {
    compute = computation.compute;
    dependencies = computation.dependencies;
    condition = computation.condition;
    multi = computation.multi;
  }

  return {compute, dependencies, condition, multi};
};

const addDependenciesForField = ({computationsToRun, computations, field}) => {
  for (let key in computations) {
    const computation = computations[key];
    let {compute, dependencies, multi} =
      separateComputeAndDependencies(computation);

    if (!compute || !dependencies || !dependencies.length) {
      continue;
    }

    if (dependencies.includes(field) && !computationsToRun[key]) {
      computationsToRun[key] = {compute, dependencies, multi};
      addDependenciesForField({
        computationsToRun,
        computations,
        field: key,
      });
    }
  }
};

export const useComputations = (
  {computations, _parentValues, onError},
  form,
) => {
  let nestedComputations = computations?.nestedComputations;
  const {values, setFieldValue, setStatus, status, dirty} = form;
  const currentValuesRef = useRef(values);
  const prevValuesRef = useRef(values);
  const computationsRef = useRef({});
  const nestedComputationsRef = useRef({});
  const twoLevelNestedComputationsRef = useRef({});
  const computingInProgressRef = useRef(0);
  const statusRef = useRef(status);
  const multiValueRef = useRef({});

  const setMultiValues = ({multiValue}) => {
    const key = Object.keys(multiValue)[0];
    const keyValue = multiValue[key];
    delete multiValue[key];
    setFieldValue(key, keyValue);
  };

  const executeComputations = async () => {
    const prevValues = prevValuesRef.current;
    let computationsToRun = computationsRef.current;
    let nestedComputationsToRun = nestedComputationsRef.current;
    let twoLevelNestedComputationsToRun = twoLevelNestedComputationsRef.current;
    let multiValue = multiValueRef.current;
    if (Object.keys(multiValue).length) {
      setMultiValues({multiValue});
      return;
    }
    if (computingInProgressRef.current > 0) {
      return;
    }

    prevValuesRef.current = currentValuesRef.current;

    for (let field of Object.keys(computations)) {
      const computation = computations[field];

      let {
        compute,
        dependencies,
        condition = true,
        multi,
      } = separateComputeAndDependencies(computation);

      if (!compute) {
        continue;
      }

      if (
        prevValues &&
        dependencies &&
        dependencies.some(
          dep =>
            !lodash.isEqual(currentValuesRef.current[dep], prevValues[dep]),
        )
      ) {
        if (typeof condition === 'function') {
          condition = condition(currentValuesRef.current, {_parentValues});
        }
        if (condition) {
          computationsToRun[field] = {compute, dependencies, multi};
        }
      }
    }
    //check if there is change in nested computations
    for (let key in nestedComputations) {
      const nestedKeyComputations = nestedComputations[key];
      const {
        nestedComputations: level2Computations,
        ...restNestedComputations
      } = nestedKeyComputations;
      //check if dependencies starts with _parentValues and if so, check if there is change in _parentValues
      for (let field in restNestedComputations) {
        const computation = restNestedComputations[field];
        let {
          compute,
          dependencies,
          condition = true,
          multi,
        } = separateComputeAndDependencies(computation);

        if (!compute || !dependencies || !dependencies.length) {
          continue;
        }
        // check if there is condition match in some of the nested value
        const nestedKeyValue = currentValuesRef.current[key];
        if (Array.isArray(nestedKeyValue) && typeof condition === 'function') {
          // check if any one condition is true for nested value array
          condition = nestedKeyValue.some(nestedValue =>
            condition(nestedValue, {_parentValues: currentValuesRef.current}),
          );
        }

        if (!condition) {
          continue;
        }

        for (let i = 0; i < dependencies.length; i++) {
          const dependency = dependencies[i];
          const dotIndex = dependency.indexOf('.');
          const preDotExpression = dependency.substring(0, dotIndex);
          const postDotExpression = dependency.substring(dotIndex + 1);
          if (
            preDotExpression === '_parentValues' &&
            prevValues &&
            currentValuesRef.current &&
            postDotExpression &&
            !lodash.isEqual(
              currentValuesRef.current[postDotExpression],
              prevValues[postDotExpression],
            )
          ) {
            // computation required for this field
            nestedComputationsToRun[key] = nestedComputationsToRun[key] || {};
            nestedComputationsToRun[key][field] = {
              compute,
              dependencies,
              condition,
              multi,
            };
            addDependenciesForField({
              computationsToRun: nestedComputationsToRun[key],
              computations: nestedKeyComputations,
              field,
            });
          }
        }
      }

      for (let level2Key in level2Computations) {
        const nestedKeyComputations = level2Computations[level2Key];
        for (let field in nestedKeyComputations) {
          const computation = nestedKeyComputations[field];
          let {
            compute,
            dependencies,
            condition = true,
            multi,
          } = separateComputeAndDependencies(computation);

          if (!compute || !dependencies || !dependencies.length) {
            continue;
          }
          const nestedKeyValue = currentValuesRef.current[key];
          // check if there is condition match in some of the nested value

          let conditionMatched = true;

          if (
            Array.isArray(nestedKeyValue) &&
            typeof condition === 'function'
          ) {
            // check if any one condition is true for nested value array
            let match = false;
            for (let i = 0; i < nestedKeyValue.length; i++) {
              let nestedValue = nestedKeyValue[i];
              if (Array.isArray(nestedValue[level2Key])) {
                match = nestedValue[level2Key].some(nestedValue2 =>
                  condition(nestedValue2, {
                    _parentValues: {
                      ...nestedValue,
                      _parentValues: currentValuesRef.current,
                    },
                  }),
                );
              }
              if (match) {
                break;
              }
            }
            conditionMatched = match;
          }

          if (!conditionMatched) {
            continue;
          }

          for (let i = 0; i < dependencies.length; i++) {
            const dependency = dependencies[i];
            const level = dependency.split('.').reduce((acc, curr) => {
              if (curr === '_parentValues') {
                return acc + 1;
              }
              return acc;
            }, 0);

            const lastDotIndex = dependency.lastIndexOf('.');
            const postDotExpression = dependency.substring(lastDotIndex + 1);
            if (
              level === 2 &&
              prevValues &&
              currentValuesRef.current &&
              postDotExpression
            ) {
              if (
                !lodash.isEqual(
                  currentValuesRef.current[postDotExpression],
                  prevValues[postDotExpression],
                )
              ) {
                // computation required for this field
                twoLevelNestedComputationsToRun[key] =
                  twoLevelNestedComputationsToRun[key] || {};
                twoLevelNestedComputationsToRun[key][level2Key] =
                  twoLevelNestedComputationsToRun[key][level2Key] || {};
                twoLevelNestedComputationsToRun[key][level2Key][field] = {
                  compute,
                  dependencies,
                  condition,
                  multi,
                };

                addDependenciesForField({
                  computationsToRun:
                    twoLevelNestedComputationsToRun[key][level2Key],
                  computations: nestedKeyComputations,
                  field,
                });
              }
            }
          }
        }
      }
    }
    let fields = Object.keys(computationsToRun);
    let nestedFields = Object.keys(nestedComputationsToRun);
    let twoLevelNestedFields = Object.keys(twoLevelNestedComputationsToRun);

    // set status to computing if there are computations to run
    if (
      (fields.length || nestedFields.length || twoLevelNestedFields.length) &&
      statusRef.current !== 'computing'
    ) {
      setStatus('computing');
    }

    let computationRun = false;
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      const {compute, multi} = computationsToRun[field];
      delete computationsToRun[field];

      try {
        computingInProgressRef.current++;
        const newValue = await compute(currentValuesRef.current, {
          _parentValues,
        });
        computingInProgressRef.current--;
        if (multi) {
          for (let key in newValue) {
            if (!lodash.isEqual(currentValuesRef.current[key], newValue[key])) {
              multiValue[key] = newValue[key];
            }
          }
          // if there is multi value then set it and break
          if (Object.keys(multiValue).length) {
            setMultiValues({multiValue});
            computationRun = true;
            break;
          }
        } else {
          if (!lodash.isEqual(currentValuesRef.current[field], newValue)) {
            setFieldValue(field, newValue);
            computationRun = true;
            break;
          }
        }
      } catch (err) {
        computingInProgressRef.current--;
        onError && onError(err);
      }
    }
    //now run for nested computations
    if (!computationRun) {
      const nestedComputationFields = Object.keys(nestedComputationsToRun);

      for (let key of nestedComputationFields) {
        const nestedKeyComputations = nestedComputationsToRun[key];
        delete nestedComputationsToRun[key];
        const nestedKeyValue = currentValuesRef.current[key];

        // now we run for each value of nestedKeyValue

        if (Array.isArray(nestedKeyValue)) {
          let newNestedValue = [];
          for (let i = 0; i < nestedKeyValue.length; i++) {
            let nestedValue = {...nestedKeyValue[i]};
            for (let field in nestedKeyComputations) {
              let {compute, multi} = nestedKeyComputations[field];
              try {
                computingInProgressRef.current++;
                const newValue = await compute(nestedValue, {
                  _parentValues: currentValuesRef.current,
                });
                computingInProgressRef.current--;
                if (multi) {
                  for (let key in newValue) {
                    if (!lodash.isEqual(nestedValue[key], newValue[key])) {
                      nestedValue[key] = newValue[key];
                      computationRun = true;
                    }
                  }
                } else {
                  if (!lodash.isEqual(nestedValue[field], newValue)) {
                    nestedValue[field] = newValue;
                    computationRun = true;
                  }
                }
              } catch (err) {
                computingInProgressRef.current--;
                onError && onError(err);
              }
            }
            newNestedValue.push(nestedValue);
          }
          if (computationRun) {
            setFieldValue(key, newNestedValue);
            break;
          }
        }
      }

      // now run for two level nested computations
      if (!computationRun) {
        const twoLevelNestedComputationFields = Object.keys(
          twoLevelNestedComputationsToRun,
        );

        for (let key of twoLevelNestedComputationFields) {
          const nestedKeyComputations = twoLevelNestedComputationsToRun[key];
          delete twoLevelNestedComputationsToRun[key];
          const nestedKeyValue = currentValuesRef.current[key];

          // now we run for each value of nestedKeyValue

          if (Array.isArray(nestedKeyValue)) {
            let newNestedValue = [];

            for (let i = 0; i < nestedKeyValue.length; i++) {
              let nestedValue = {...nestedKeyValue[i]};

              // now we are checking for level 1 dependencies with current level 2 computation
              let level1Dependency = {};

              for (let level2Key in nestedKeyComputations) {
                addDependenciesForField({
                  computationsToRun: level1Dependency,
                  computations: nestedComputations[key],
                  field: level2Key,
                });

                const level2NestedKeyComputations =
                  nestedKeyComputations[level2Key];
                const level2NestedKeyValue = nestedValue[level2Key];
                if (Array.isArray(level2NestedKeyValue)) {
                  let newLevel2NestedValue = [];
                  for (
                    let level2Index = 0;
                    level2Index < level2NestedKeyValue.length;
                    level2Index++
                  ) {
                    let level2NestedValue = {
                      ...level2NestedKeyValue[level2Index],
                    };
                    for (let field in level2NestedKeyComputations) {
                      let {compute, multi} = level2NestedKeyComputations[field];
                      try {
                        computingInProgressRef.current++;
                        const newValue = await compute(level2NestedValue, {
                          _parentValues: {
                            ...nestedValue,
                            _parentValues: currentValuesRef.current,
                          },
                        });
                        computingInProgressRef.current--;
                        if (multi) {
                          for (let key in newValue) {
                            if (
                              !lodash.isEqual(
                                level2NestedValue[key],
                                newValue[key],
                              )
                            ) {
                              level2NestedValue[key] = newValue[key];
                              computationRun = true;
                            }
                          }
                        } else if (
                          !lodash.isEqual(level2NestedValue[field], newValue)
                        ) {
                          level2NestedValue[field] = newValue;
                          computationRun = true;
                        }
                      } catch (err) {
                        computingInProgressRef.current--;
                        onError && onError(err);
                      }
                    }
                    newLevel2NestedValue.push(level2NestedValue);
                  }
                  nestedValue[level2Key] = newLevel2NestedValue;
                }
              }

              // start here
              // now we are running for level 1 computation

              for (let field in level1Dependency) {
                let {compute, multi} = level1Dependency[field];
                try {
                  computingInProgressRef.current++;
                  const newValue = await compute(nestedValue, {
                    _parentValues: currentValuesRef.current,
                  });
                  computingInProgressRef.current--;
                  if (multi) {
                    for (let key in newValue) {
                      if (!lodash.isEqual(nestedValue[key], newValue[key])) {
                        nestedValue[key] = newValue[key];
                      }
                    }
                  } else {
                    if (!lodash.isEqual(nestedValue[field], newValue)) {
                      nestedValue[field] = newValue;
                    }
                  }
                } catch (err) {
                  computingInProgressRef.current--;
                  onError && onError(err);
                }
              }
              //end here

              newNestedValue.push(nestedValue);
            }
            if (computationRun) {
              setFieldValue(key, newNestedValue);
              break;
            }
          }
        }
      }
    }
    // if no computation to run then remove status
    if (
      !Object.keys(computationsToRun)?.length &&
      !Object.keys(nestedComputationsToRun)?.length &&
      !Object.keys(twoLevelNestedComputationsToRun)?.length &&
      statusRef.current === 'computing' &&
      computingInProgressRef.current === 0
    ) {
      setStatus();
    }
  };

  useEffect(() => {
    if (!computations || (computations && !Object.keys(computations).length)) {
      return;
    }

    if (!dirty) {
      prevValuesRef.current = values;
      return;
    }
    currentValuesRef.current = values;
    statusRef.current = status;
    const _fn = () => {
      executeComputations();
    };
    _fn();
  }, [values, dirty, status]);
};
