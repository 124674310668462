import React, {useCallback, useState} from 'react';

export const useUploadFiles = ({upload, options, onUploadFile}) => {
  const [uploading, setUploading] = useState();

  const uploadFiles = useCallback(
    async (fileValues, e) => {
      if (!fileValues || !fileValues.length) {
        return;
      }
      setUploading(true);
      try {
        let result = [];
        for (let fileValue of fileValues) {
          const fileResult = await upload(fileValue, options);
          result.push(fileResult);
        }
        onUploadFile && onUploadFile(result, e);
      } catch (err) {
        console.warn('!!!!! Error in file upload >>>>>', err);
      }
      setUploading(false);
    },
    [upload, options, onUploadFile],
  );
  return {
    uploadFiles,
    uploading,
  };
};
