import React from 'react';
import {
  View,
  Text,
  Styled,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
} from '@unthinkable/react-core-components';

export const FileRenderWrapper = Styled(TouchableOpacity)`
cursor: pointer;
justify-content: center;
height: 70px;
min-width: 100px;
max-width: 150px;
padding: 4px;
border-width: 1px;
border-color: #EEEEED;
border-radius: 4px;
background-color: #fff;
${({styles, showText, selected}) => {
  return {
    ...styles?.container,
    ...(selected && {
      cursor: 'pointer',
      justifyContent: 'center',
      height: 70,
      minWidth: 100,
      maxWidth: 150,
      padding: 4,
      borderWidth: 3,
      borderColor: '#7DCD28',
      borderRadius: 4,
      backgroundColor: '#fff',
      ...styles?.selectedContainer,
    }),
    ...(showText ? {maxWidth: 200, padding: 8, ...styles?.textContainer} : {}),
  };
}}`;

export const FileRemoveWrapper = Styled(TouchableOpacity)`
  position: absolute;
        top: -8px;
        right: -8px;
  ${({styles}) => styles?.removePosition}`;

export const FileRenderImageContainer = Styled(View)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow:hidden
`;

export const FileRenderImageNativeContainer = Styled(View)`
  justify-content: center;
  align-items: center;
`;

export const FileRemoveInnerContainer = Styled(View)`
cursor: pointer;
height: 18px;
width: 18px;
border-radius: 18px;
background-color: #fff;
border-width: 1px;
border-color: #EEEEED;
align-items: center;
justify-content: center;

${({styles}) => styles?.removeContainer}`;

export const RemoveNativeInnerContainer = Styled(View)`

${({styles}) => styles?.removeNativeInnerContainer}`;

export const FileRenderText = Styled(Text)`
font-size: 14px;
font-family:Inter-Regular;
line-height: 20px;
color: #19191A;
overflow:hidden;
${({styles}) => {
  return {
    ...styles?.text,
  };
}}`;
export const FileRemoveText = Styled(Text)`
font-size: 10px;
font-family: Inter-Medium;
line-height: 10px;
color: #7D7D82;
${({styles}) => styles?.removeText}`;

export const FileRenderImage = Styled(Image)`max-height: 100%;
max-width: 100%;`;

export const FileRenderImageNative = Styled(Image)`${({imageSize, isLoaded}) =>
  !isLoaded
    ? {width: 0, height: 0}
    : {
        maxWidth: '100%',
        maxHeight: '100%',
        width: imageSize.width,
        height: imageSize.height,
      }}`;

export const FileInputWrapper = Styled(View)`flex: 1`;

export const AddAttachmentContainer = Styled(View)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  padding: 4px;
  border-width: 1px;
  border-style: dashed;
  border-color: #A3A3A3;
  border-radius: 8px;
  background-color: #fff;
${({styles, error}) => {
  return {...styles?.container, ...(error && styles?.error)};
}}`;

export const AddAttachmentTextContainer = Styled(View)`
flex: 1; overflow: hidden;
`;

export const AddAttachmentText = Styled(Text)`
font-size: 14px;
font-family: Inter-Regular;
line-height: 20px;
color: #7D7D82;
${({styles}) => {
  return {
    ...styles?.text,
  };
}}`;

export const MultipleFileActionContainer = Styled(TouchableOpacity)`
  margin-top: 8px;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  background-color: #F7F7F7;
  margin-left: 6px;
  margin-right: 6px;
  ${({styles, disabled}) => {
    return {
      cursor: disabled ? 'default' : 'pointer',
      userSelect: 'none',
      opacity: disabled ? 0.3 : 1,
      ...styles?.scroll?.iconContainer,
    };
  }}`;

export const MultipleFileContainer = Styled(View)`
  flex-direction: row;
  align-items: center;
${({styles}) => styles?.container}`;

export const MultipleFileActionText = Styled(Text)`
  padding: 6px;
  font-size: 14px;
  font-family: Inter-Medium;
  line-height: 18px;
  color: #7D7D82;
  ${({styles}) => styles?.scroll?.iconText}`;

export const MultiFileActionIcon = Styled(Image)`
  height: 14px;
  ${({styles, scrollPosition}) => {
    return {
      ...styles?.scroll?.icon,
      ...(scrollPosition === 'left'
        ? {
            transform:
              Platform.OS === 'web' ? 'rotate(180deg)' : [{rotate: '180deg'}],
          }
        : {}),
    };
  }}
  `;

export const MultipleFileScroll = Styled(ScrollView)`
  flex: 1;
  scroll-behavior: smooth; 
  padding-top: 8px;
  ${({styles}) => styles?.scroll?.style}`;

export const WrapMultipleContainer = Styled(View)`${({styles}) =>
  styles?.container}`;
