import {
  Text,
  Row,
  Styled,
  View,
  TouchableOpacity,
  Image,
} from '@unthinkable/react-core-components';

export const AvatarContainer = Styled(View).withConfig({
  shouldForwardProp: prop =>
    ![
      'containerStyle',
      'size',
      'backgroundColor',
      'borderColor',
      'borderWidth',
      'variant',
    ].includes(prop),
})`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  background-color:#32ADE6;
${props => props.styles?.container}  
${({
  styles,
  containerStyle,
  size,
  backgroundColor,
  borderColor,
  borderWidth,
  variant,
}) => {
  return {
    ...(size && typeof size === 'string' && styles[size]),
    ...(size && typeof size === 'number'
      ? {width: size, height: size, borderRadius: size / 2}
      : void 0),
    ...(containerStyle ? containerStyle : void 0),
    ...(backgroundColor ? {backgroundColor} : void 0),
    ...(borderColor ? {borderWidth: 1, borderColor} : void 0),
    ...(borderWidth ? {borderWidth} : void 0),
    ...(variant === 'square' ? {borderRadius: 0} : void 0),
  };
}}
`;

export const AvatarText = Styled(Text).withConfig({
  shouldForwardProp: prop =>
    !['color', 'size', 'fontSize', 'lineHeight'].includes(prop),
})`
font-size: 12px;
line-height: 16px;
color: #FFFFFF;
${({styles, color, size, fontSize, lineHeight}) => {
  return {
    ...styles?.text,
    ...(color ? {color} : void 0),
    ...(size
      ? {
          fontSize: `${Math.ceil(size / 2)}px`,
          lineHeight: `${Math.ceil(size / 2)}px`,
        }
      : void 0),
    ...(fontSize && lineHeight
      ? {
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
        }
      : void 0),
  };
}}
`;

export const AvatarGroupContainer = Styled(Row)`
${props => props.styles?.container}  
${({direction}) => {
  return {
    ...(direction ? {flexDirection: direction} : void 0),
    ...(direction === 'row-reverse' || direction === 'column-reverse'
      ? {justifyContent: 'flex-end'}
      : void 0),
  };
}}
`;
