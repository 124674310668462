/** @format */
import React, {forwardRef} from 'react';
import View from '../View';
import Styled from '../styled-components';

const StyledView = Styled(View).withConfig({
  shouldForwardProp: prop => !['overrideStyle'].includes(prop),
})`
  ${props => (props.disabled ? {cursor: 'not-allowed'} : {cursor: 'pointer'})}
  ${props => props.overrideStyle}
`;

const TouchableOpacity = forwardRef(({style, ...props}, ref) => {
  return <StyledView {...props} overrideStyle={style} ref={ref} />;
});

export default TouchableOpacity;
