export const breakpointWidths = {
  sm: 320,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const getCurrentBreakpoint = (width, breakpoints = breakpointWidths) => {
  if (width < breakpoints.md) {
    return 'sm';
  } else if (width < breakpoints.lg) {
    return 'md';
  } else if (width < breakpoints.xl) {
    return 'lg';
  } else {
    return 'xl';
  }
};
