import {useRef, useState, useCallback} from 'react';
import {useAutoCompleteFetch} from './useAutoCompleteFetch';
import {filterData} from '../Utility';

export const useFetchSuggestions = ({
  minChar,
  onFetchSuggestions,
  cache,
  options,
  initialSuggestions,
  ...props
}) => {
  const {suggestionField} = props;
  const {loading: fetching, fetch} = useAutoCompleteFetch(props);
  const [suggestions, setSuggestions] = useState(initialSuggestions);

  const lastFetchSequence = useRef(0);
  const [cacheSuggestions, setCacheSuggestions] = useState();

  const _setSuggestions = suggestions => {
    setSuggestions(suggestions);
    onFetchSuggestions?.(suggestions);
  };

  const fetchSuggestions = useCallback(
    async ({searchValue} = {}) => {
      if (minChar && (!searchValue || searchValue.length < minChar)) {
        _setSuggestions([]);
        return [];
      }
      if (!fetch) {
        console.warn('fetch Must be defined in useFetchSuggestions');
      }
      try {
        let _cacheSuggestions = cacheSuggestions;
        if (cache && !_cacheSuggestions) {
          _cacheSuggestions = await fetch({
            searchValue: '',
          });
          setCacheSuggestions(_cacheSuggestions);
        }

        lastFetchSequence.current++;
        let sequence = lastFetchSequence.current;

        let result;
        if (options?.length) {
          result = filterData({data: options, searchValue, suggestionField});
        } else if (_cacheSuggestions?.length) {
          result = filterData({
            data: _cacheSuggestions,
            searchValue,
            suggestionField,
          });
        } else {
          result = await fetch({
            searchValue,
          });
        }
        if (lastFetchSequence.current === sequence) {
          _setSuggestions(result);
        }
        return result;
      } catch (err) {
        console.warn(
          'Error in fetching in  useFetchSuggestions >>>> ',
          err.message,
        );
      }
    },
    [
      cache,
      minChar,
      fetch,
      setSuggestions,
      onFetchSuggestions,
      lastFetchSequence,
      cacheSuggestions,
    ],
  );

  const resetCache = useCallback(() => {
    setCacheSuggestions();
  }, [cache, setCacheSuggestions]);

  return {
    fetching,
    suggestions,
    fetchSuggestions,
    resetCache,
  };
};
