import AuthRoutes from '../modules/auth/AuthRoutes';

const leftMenuRoutes = [];
const stackRoutes = [...AuthRoutes.stack];
const ModalRoutes = [];

export default {
  leftMenu: leftMenuRoutes,
  stack: stackRoutes,
  modal: ModalRoutes,
};
