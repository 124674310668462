import React, {forwardRef} from 'react';

import View from './View';
import Styled from './styled-components';

const StyledRow = Styled(View).withConfig({
  shouldForwardProp: prop => !['overrideStyle', 'gap'].includes(prop),
})`
  flex-direction: row;
  ${props => props.overrideStyle}
  ${props => (typeof props.gap === 'number' ? {gap: props.gap} : {})}
`;

const Row = forwardRef(({children, style, ...restProps}, ref) => {
  return (
    <StyledRow ref={ref} overrideStyle={style} {...restProps}>
      {children}
    </StyledRow>
  );
});

export default Row;
