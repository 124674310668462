export default {
  default: {
    numberOfLines: 1,
    header: {
      numberOfLines: 1,
    },
  },
  text: {
    numberOfLines: 1,
  },
  textArea: {
    numberOfLines: 3,
  },
  date: {
    width: 130,
  },
  number: {
    width: 150,
    align: 'right',
  },
  currency: {
    width: 150,
    align: 'right',
  },
  indianCurrency: {
    width: 150,
    align: 'right',
  },
  percentage: {
    width: 150,
    align: 'right',
  },
  selection: {
    width: 50,
  },
  moreActions: {
    width: 50,
  },
};
