import {useEffect, useState} from 'react';

export const useFetchFormData = ({
  fetch,
  data,
  defaultValues,
  updateDefaultValues,
  mode,
  api,
  fields,
  filter,
  params,
  addOnFilter,
  only = true,
  limit = 1,
  onDataError,
  encryption,
}) => {
  let [loading, setLoading] = useState();
  let [state, setState] = useState({
    data: mode === 'edit' ? data : defaultValues,
  });

  const loadData = async () => {
    if (api) {
      try {
        if (!fetch) {
          throw new Error('fetch is not defined');
        }
        setLoading(true);
        let result = await fetch({
          uri: api,
          props: {
            fields,
            filter,
            addOnFilter,
            params,
            only,
            limit,
          },
          encryption,
        });
        if (Array.isArray(result.data)) {
          result.data = result.data[0];
        }
        if (updateDefaultValues && result.data) {
          result.data = {...updateDefaultValues, ...result.data};
        }
        setState(result);
      } catch (err) {
        onDataError && onDataError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (mode === 'edit' && !data) {
      loadData();
    }
  }, []);

  return {
    ...state,
    loading,
  };
};
