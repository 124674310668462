import React, {useState} from 'react';
import {Col, Row, View} from '@unthinkable/react-core-components';
import {
  useResolveBreakpoint,
  useResponsiveBreakpoint,
} from '@unthinkable/react-utils';

export const Grid = ({children, columns = 12, colGap, rowGap}) => {
  const [gridWidth, setGridWidth] = useState();

  const currentBreakpoint = useResponsiveBreakpoint();
  const renderRows = () => {
    // Split children into rows based on their size
    const rows = [];
    let currentRow = [];
    let currentCount = 0;
    React.Children.forEach(children, (child, index) => {
      if (!child) return;
      let {size = columns, width, maxWidth} = child?.props || {};
      if (size === 'small') {
        size = columns / 3;
      } else if (size === 'medium') {
        size = columns / 2;
      } else if (size === 'large') {
        size = columns;
      }
      let resolvedSize = size;
      if (size && Object.keys(size).length) {
        resolvedSize = useResolveBreakpoint({breakpointObj: size});
      } else if (currentBreakpoint === 'sm') {
        resolvedSize = columns;
      }
      // if the field has a fixed width, convert it to a fraction of the total width
      if (width && gridWidth) {
        let totalGap = currentRow.length > 0 ? currentRow.length * rowGap : 0;
        let totalWidth = gridWidth - totalGap;
        const widthInPixels = parseInt(width, 10);
        resolvedSize = (widthInPixels / totalWidth) * columns;
      }

      if (currentCount + resolvedSize > columns) {
        // Start a new row if adding the current field would exceed columns
        rows.push(<Row gap={rowGap}>{currentRow}</Row>);
        currentRow = [];
        currentCount = 0;
      }
      let itemStyle = width ? {width} : {flex: resolvedSize};
      currentRow.push(
        <View key={index} style={{...itemStyle, maxWidth, overflow: 'hidden'}}>
          {child}
        </View>,
      );
      currentCount += resolvedSize;
    });
    if (currentRow.length > 0) {
      rows.push(<Row gap={rowGap}>{currentRow}</Row>);
    }
    return rows;
  };
  return (
    <Col
      onLayout={e => {
        const {width} = e.nativeEvent.layout;
        if (gridWidth !== width) {
          setGridWidth(width);
        }
      }}
      gap={colGap}>
      {renderRows()}
    </Col>
  );
};

export const GridItem = ({children}) => {
  return children;
};
