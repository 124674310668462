import React from 'react';
import {Styled, Text} from '@unthinkable/react-core-components';

const HelperText = Styled(Text)`
font-size: 12px;
color: #7D7D82;
padding-top: 5px;
${props => props?.styles?.helperText}
`;

const HelperMessage = ({helperText, styles}) => {
  if (!helperText) {
    return null;
  }
  return <HelperText styles={styles}>{helperText}</HelperText>;
};

export default HelperMessage;
