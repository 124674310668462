import {FormikContext, FormikProvider, useFormikContext} from 'formik';

const FormContext = FormikContext;

const FormProvider = FormikProvider;

const useFormContext = () => {
  return useFormikContext();
};

export {FormContext, FormProvider, useFormContext};
