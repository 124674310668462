import React from 'react';
import {ToastProvider as ReactToastProvider} from '@unthinkable/react-toast';
import {Toast} from '../../components/toast';

export const ToastProvider = props => {
  const {children} = props || {};
  return (
    <ReactToastProvider>
      {children}
      <Toast />
    </ReactToastProvider>
  );
};
