import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';

const TextRender = (props) => {
  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} />
    </InputLabel>
  );
};

export default TextRender;
