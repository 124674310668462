import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {ToastComponent} from '@unthinkable/react-toast';
import {toastStyles} from './Toast.styles';

const Toast = props => {
  let styles = useStyles(toastStyles);
  return <ToastComponent styles={styles} {...props} />;
};

export default Toast;
