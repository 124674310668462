import {useMemo} from 'react';
import DefaultColumnStyles from '../ColumnStyles';
import DefaultColumnFormats from '../ColumnFormats';
import {useResponsiveProps} from './useResponsiveProps';
import lodash from 'lodash';

export const useResolveProps = ({
  columnStyles: columnStylesProp,
  columnFormats: columnFormatsProp,
  ...props
}) => {
  props = useResponsiveProps(props);
  const columnStyles = useMemo(() => {
    return lodash.merge({}, DefaultColumnStyles, columnStylesProp);
  }, []);
  const columnFormats = useMemo(() => {
    return lodash.merge({}, DefaultColumnFormats, columnFormatsProp);
  }, []);
  return {
    columnStyles,
    columnFormats,
    ...props,
  };
};
