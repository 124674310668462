import React from 'react';
import {Wrap} from '@unthinkable/react-core-components';
import {FileRender} from './FileRender';
import {WrapMultipleContainer} from './Styles';

export const WrapMultipleFileRender = ({
  styles,
  values,
  itemGap,
  onRemove,
  AddComponent,
  ...props
}) => {
  if (!values || !values.length) {
    return null;
  }
  return (
    <WrapMultipleContainer styles={styles}>
      <Wrap gap={itemGap}>
        {AddComponent}
        {values.map((_value, index) => {
          return (
            <FileRender
              styles={styles}
              {...props}
              value={_value}
              onRemove={onRemove ? () => onRemove({index}) : void 0}
            />
          );
        })}
      </Wrap>
    </WrapMultipleContainer>
  );
};
