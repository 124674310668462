import React from 'react';
import {MediaQueryProvider as ReactMediaQueryProvider} from '@unthinkable/react-theme';
import theme from '../../theme';

const {
  base: {breakpoints},
} = theme || {};

export const MediaQueryProvider = props => {
  const {children} = props || {};
  return (
    <ReactMediaQueryProvider breakpoints={breakpoints}>
      {children}
    </ReactMediaQueryProvider>
  );
};
