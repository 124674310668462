import React from 'react';
import moment from 'moment';
import {
  StyledView,
  WeekView,
  MonthView,
  StyledContainer,
  MonthContainer,
  MonthHeaderContainer,
  YearHeaderContainer,
  YearHeader,
  YearContainer,
  HeaderContainer,
  WeekContainer,
  DateContainer,
  DatesList,
  PrevNextContainer,
  StyledImage,
  YearHeaderText,
  YearText,
  WeekText,
  DateText,
  MonthYearText,
  PrevNextText,
  DateRow,
  YearRow,
  YearListContainer,
  RenderWrapper,
  MonthsRow,
  MonthText,
} from './styles/CalendarStyles';
import {Row} from '@unthinkable/react-core-components';

class PrevHandler extends React.Component {
  render() {
    let {onClick, styles = {}} = this.props;
    const {prevIcon: icon} = styles;

    return (
      <PrevNextContainer styles={styles} onClick={onClick}>
        {icon ? (
          <StyledImage styles={styles} source={icon} />
        ) : (
          <PrevNextText styles={styles}>{'<'}</PrevNextText>
        )}
      </PrevNextContainer>
    );
  }
}

class NextHandler extends React.Component {
  render() {
    let {onClick, styles = {}} = this.props;
    const {nextIcon: icon} = styles;

    return (
      <PrevNextContainer styles={styles} onClick={onClick}>
        {icon ? (
          <StyledImage styles={styles} source={icon} />
        ) : (
          <PrevNextText styles={styles}>{'>'}</PrevNextText>
        )}
      </PrevNextContainer>
    );
  }
}

class Week extends React.Component {
  render() {
    let {children, primary, styles = {}} = this.props;
    return (
      <WeekView styles={styles} primary={primary}>
        <WeekText styles={styles} primary={primary}>
          {children}
        </WeekText>
      </WeekView>
    );
  }
}

class DateView extends React.Component {
  onChange = e => {
    e.preventDefault();
    let {onChange, value, year, month, date, hideModal, select} = this.props;
    value = new Date();
    if (!value) {
      value = new Date();
    }
    value.setFullYear(year);
    value.setMonth(month, date);
    select && select(value);
    onChange && onChange(value, e);
    hideModal && hideModal();
  };
  render() {
    let {
      children,
      deActive,
      selected,
      onChange,
      date,
      month,
      year,
      renderDate,
      styles,
      ...rest
    } = this.props;
    if (renderDate) {
      let renderDateProps = {date, year, month, deActive, selected};
      if (React.isValidElement(renderDate)) {
        renderDate = React.cloneElement(renderDate, renderDateProps);
      } else if (typeof renderDate === 'function') {
        renderDate = renderDate(renderDateProps);
      }
    }
    return (
      <DateContainer
        styles={styles}
        selected={selected}
        deActive={deActive}
        onClick={this.onChange}>
        {renderDate || (
          <DateText
            styles={styles}
            deActive={deActive}
            selected={selected}
            {...rest}>
            {children > 9 ? children : `0${children}`}
          </DateText>
        )}
      </DateContainer>
    );
  }
}

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    let {value, format = 'DD MMM YY'} = this.props;
    let currentDate = value
      ? moment(value, format).toDate()
      : moment(new Date(), format).toDate();
    this.state = this.getState(currentDate);
  }
  select = value => {
    this.setState({selectedDate: moment(value).format('D')});
  };
  getState(currentDate) {
    return {
      date: currentDate.getDate(),
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
      visibleView: 'dateView',
      yearViewBase: currentDate.getFullYear(),
      selectedDate: null,
    };
  }
  componentWillReceiveProps({value, format = 'DD MMM YY'}) {
    if (value && value !== this.oldDate) {
      let currentDate = value
        ? moment(value, format).toDate()
        : moment(new Date(), format).toDate();

      this.setState(this.getState(currentDate));
    }
    this.oldDate = moment(new Date(), format).toDate();
  }
  getDates({date, month, year}) {
    const {onChange, value, renderDate, hideModal, styles} = this.props;
    let dates = [];
    let currMonLstDtObj = new Date(year, month + 1, 0);
    let currMonLstDt = currMonLstDtObj.getDate();
    let currMonLstDay = currMonLstDtObj.getDay();
    let prevMonlstDtObj = new Date(year, month, 0);
    let prevMonLstDt = prevMonlstDtObj.getDate();
    let prevMonLstDay = prevMonlstDtObj.getDay();
    let perRowDate = 0;
    let dateToRender = [];
    while (prevMonLstDay >= 0 && prevMonLstDay < 6) {
      dates.unshift(
        <DateView
          styles={styles}
          deActive
          key={'prev' + prevMonLstDt}
          date={prevMonLstDt}
          renderDate={renderDate}>
          {prevMonLstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.unshift(<DateRow>{dates}</DateRow>);
        dates = [];
      }
      prevMonLstDt--;
      prevMonLstDay--;
    }
    let currMonFstDt = 1;
    date = (this.state.selectedDate && Number(this.state.selectedDate)) || date;
    while (currMonFstDt <= currMonLstDt) {
      dates.push(
        <DateView
          styles={styles}
          key={currMonFstDt}
          selected={date === currMonFstDt}
          date={currMonFstDt}
          month={month}
          year={year}
          value={value}
          onChange={onChange}
          hideModal={hideModal}
          select={this.select}
          renderDate={renderDate}>
          {currMonFstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(<Row>{dates}</Row>);
        dates = [];
      }
      currMonFstDt++;
    }
    currMonLstDay = 7 - currMonLstDay;
    let i = 1;
    while (i < currMonLstDay) {
      dates.push(
        <DateView
          styles={styles}
          deActive
          key={'next' + i}
          date={i}
          renderDate={renderDate}>
          {i}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(<Row>{dates}</Row>);
        dates = [];
      }
      i++;
    }

    return <React.Fragment>{dateToRender}</React.Fragment>;
  }
  onDateChange = params => {
    this.props.onDateChange && this.props.onDateChange(params);
  };

  nextMonth = () => {
    let {month, year} = this.state;
    if (month === 11) {
      month = 0;
      year++;
    } else {
      month++;
    }
    // this.onDateChange({month, year});
    this.setState({month, year});
  };
  prevMonth = () => {
    let {month, year} = this.state;
    if (month === 0) {
      month = 11;
      year--;
    } else {
      month--;
    }
    // this.onDateChange({month, year});
    this.setState({month, year});
  };

  onMonthClick = month => {
    let {year} = this.state;
    // this.onDateChange({month, year});
    this.setState({month, visibleView: 'dateView'});
  };

  onYearClick = year => {
    let {month} = this.state;
    // this.onDateChange({month, year});
    this.setState({year, visibleView: 'dateView'});
  };

  getYearsArray = () => {
    let {
      yearView: {bodyStyle: {yearViewStyle = {}, yearTextStyle = {}} = {}} = {},
    } = this.props.styles || {};

    let yearBase = this.state.yearViewBase;
    let yearArray = [];
    let yearArrayToRender = [];
    for (let i = 0; i < 3; i++) {
      yearArray[i] = [];

      for (let j = 0; j < 3; j++) {
        yearArray[i][j] = yearBase + i * 3 + j;
        yearArrayToRender.push(
          <YearListContainer
            onClick={() => this.onYearClick(yearArray[i][j])}
            containerStyle={yearViewStyle}>
            <YearText textStyle={yearTextStyle}>{yearArray[i][j]}</YearText>
          </YearListContainer>,
        );
      }
    }

    return yearArrayToRender;
  };

  render() {
    const {date, month, year, visibleView} = this.state;
    let {weekNames, monthNames, styles} = this.props;
    weekNames = weekNames || ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    monthNames = monthNames || [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let render = [];

    if (visibleView === 'dateView') {
      let {
        containerStyle = {},
        header: {
          containerStyle: header_containerStyle,
          prev: prevTheme,
          next: nextTheme,
          year: {viewStyle: year_viewStyle, textStyle: year_textStyle} = {},
          month: {viewStyle: month_viewStyle, textStyle: month_textStyle} = {},
        } = {},
        body: {weekContainerStyle} = {},
      } = styles?.dataView || {};

      render = (
        <React.Fragment>
          <HeaderContainer styles={header_containerStyle}>
            <PrevHandler onClick={this.prevMonth} styles={prevTheme} />
            <MonthContainer
              styles={month_viewStyle}
              onClick={() => this.setState({visibleView: 'monthView'})}>
              <MonthYearText styles={month_textStyle}>
                {monthNames[month]}
              </MonthYearText>
            </MonthContainer>
            <YearContainer
              styles={year_viewStyle}
              onClick={() => this.setState({visibleView: 'yearView'})}>
              <MonthYearText styles={year_textStyle}>{year}</MonthYearText>
            </YearContainer>
            <NextHandler onClick={this.nextMonth} styles={nextTheme} />
          </HeaderContainer>
          <WeekContainer containerStyle={weekContainerStyle}>
            <Week styles={styles} primary>
              {weekNames[0]}
            </Week>
            <Week styles={styles}>{weekNames[1]}</Week>
            <Week styles={styles}>{weekNames[2]}</Week>
            <Week styles={styles}>{weekNames[3]}</Week>
            <Week styles={styles}>{weekNames[4]}</Week>
            <Week styles={styles}>{weekNames[5]}</Week>
            <Week styles={styles}>{weekNames[6]}</Week>
          </WeekContainer>
          <DatesList>{this.getDates({date, month, year})}</DatesList>
        </React.Fragment>
      );
      return (
        <StyledContainer containerStyle={containerStyle}>
          {render}
        </StyledContainer>
      );
    } else if (visibleView === 'monthView') {
      let {
        containerStyle,
        header: {
          containerStyle: header_containerStyle,
          prev: prevTheme,
          next: nextTheme,
          year: {viewStyle: year_viewStyle, textStyle: year_textStyle} = {},
        } = {},
        bodyStyle: {monthRowStyle, monthTextStyle, monthViewStyle} = {},
      } = styles?.monthView || {};
      let render = (
        <React.Fragment>
          <MonthHeaderContainer containerStyle={header_containerStyle}>
            <PrevHandler
              styles={prevTheme}
              onClick={() =>
                this.setState({year: year - 1, yearViewBase: year - 1})
              }
            />
            <YearHeaderContainer
              containerStyle={year_viewStyle}
              onClick={() => this.setState({visibleView: 'yearView'})}>
              <YearHeaderText textStyle={year_textStyle}>{year}</YearHeaderText>
            </YearHeaderContainer>
            <NextHandler
              styles={nextTheme}
              onClick={() =>
                this.setState({year: year + 1, yearViewBase: year + 1})
              }
            />
          </MonthHeaderContainer>
          <MonthsRow containerStyle={monthRowStyle}>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(0)}>
              <MonthText textStyle={monthTextStyle}>{'Jan'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(1)}>
              <MonthText textStyle={monthTextStyle}>{'Feb'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(2)}>
              <MonthText textStyle={monthTextStyle}>{'Mar'}</MonthText>
            </MonthView>
          </MonthsRow>
          <MonthsRow containerStyle={monthRowStyle}>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(3)}>
              <MonthText textStyle={monthTextStyle}>{'Apr'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(4)}>
              <MonthText textStyle={monthTextStyle}>{'May'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(5)}>
              <MonthText textStyle={monthTextStyle}>{'Jun'}</MonthText>
            </MonthView>
          </MonthsRow>
          <MonthsRow containerStyle={monthRowStyle}>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(6)}>
              <MonthText textStyle={monthTextStyle}>{'July'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(7)}>
              <MonthText textStyle={monthTextStyle}>{'Aug'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(8)}>
              <MonthText textStyle={monthTextStyle}>{'Sep'}</MonthText>
            </MonthView>
          </MonthsRow>
          <MonthsRow containerStyle={monthRowStyle}>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(9)}>
              <MonthText textStyle={monthTextStyle}>{'Oct'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(10)}>
              <MonthText textStyle={monthTextStyle}>{'Nov'}</MonthText>
            </MonthView>
            <MonthView
              containerStyle={monthViewStyle}
              onClick={() => this.onMonthClick(11)}>
              <MonthText textStyle={monthTextStyle}>{'Dec'}</MonthText>
            </MonthView>
          </MonthsRow>
        </React.Fragment>
      );

      return <StyledView containerStyle={containerStyle}>{render}</StyledView>;
    } else {
      let {
        containerStyle = {},
        header: {
          containerStyle: header_containerStyle = {},
          prev: prevTheme,
          next: nextTheme,
          year: {
            viewStyle: year_viewStyle = {},
            textStyle: year_textStyle = {},
          } = {},
        } = {},
        bodyStyle: {yearRowStyle, container: body_container} = {},
      } = styles?.yearView || {};
      let yearArray = this.getYearsArray();
      let yearArrayItems = [];
      let yearArrayPerRow = [];
      yearArray.map(year => {
        yearArrayItems.push(year);
        if (yearArrayItems.length === 3) {
          yearArrayPerRow.push(
            <YearRow containerStyle={yearRowStyle}>{yearArrayItems}</YearRow>,
          );
          yearArrayItems = [];
        }
      });
      let render = (
        <React.Fragment>
          <YearHeader containerStyle={header_containerStyle}>
            <PrevHandler
              styles={prevTheme}
              onClick={() =>
                this.setState({yearViewBase: this.state.yearViewBase - 9})
              }
            />
            <YearHeaderContainer containerStyle={year_viewStyle}>
              <YearHeaderText textStyle={year_textStyle}>
                {'Select Year'}
              </YearHeaderText>
            </YearHeaderContainer>
            <NextHandler
              styles={nextTheme}
              onClick={() =>
                this.setState({yearViewBase: this.state.yearViewBase + 9})
              }
            />
          </YearHeader>
          <StyledView containerStyle={body_container}>
            {yearArrayPerRow}
          </StyledView>
        </React.Fragment>
      );
      return (
        <RenderWrapper containerStyle={containerStyle}>{render}</RenderWrapper>
      );
    }
  }
}
