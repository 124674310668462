import React from 'react';
import View from './View';
import Styled from './styled-components';

const StyledColumn = Styled(View).withConfig({
  shouldForwardProp: prop => !['overrideStyle', 'gap'].includes(prop),
})`
  ${props => props.overrideStyle}
  ${props => (typeof props.gap === 'number' ? {gap: props.gap} : {})}
`;

const Col = ({style, children, ...restProps}) => {
  return (
    <StyledColumn overrideStyle={style} {...restProps}>
      {children}
    </StyledColumn>
  );
};

export default Col;
