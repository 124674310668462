import React from 'react';
import {getNumberOfLineStyle} from '../style-utility';
import Styled from '../styled-components';

const StyledText = Styled.div.withConfig({
  shouldForwardProp: prop => !['numberOfLines'].includes(prop),
})`
  ${({numberOfLines}) => getNumberOfLineStyle({numberOfLines})}
`;

const Text = props => {
  return <StyledText {...props} />;
};

export default Text;
