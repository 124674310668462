import {useEffect, useRef} from 'react';

export const useDidUpdate = (fn, deps) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, deps);
};
