import {useState, useRef, useEffect} from 'react';

const useUpdateEffect = (fn, deps) => {
  const mounted = useRef(false);

  if (typeof deps !== 'undefined' && !Array.isArray(deps)) {
    deps = [deps];
  } else if (Array.isArray(deps) && deps.length === 0) {
    console.warn(
      'Using [] as the second argument makes useUpdateEffect a noop. The second argument should either be `undefined` or an array of length greater than 0.',
    );
  }

  useEffect(
    () => {
      if (mounted.current) {
        fn();
      } else {
        mounted.current = true;
      }
    },
    // eslint-disable-next-line
    deps,
  );
  return mounted.current;
};

export function useTransition({
  duration,
  timingFunction,
  mounted,
  onEnter,
  onExit,
  onEntered,
  onExited,
}) {
  // const reduceMotion = useReducedMotion();
  // const transitionDuration = reduceMotion ? 0 : duration;
  const transitionDuration = duration;

  const [transitionStatus, setStatus] = useState(
    mounted ? 'entered' : 'exited',
  );
  const timeoutRef = useRef(-1);
  const handleStateChange = shouldMount => {
    const preHandler = shouldMount ? onEnter : onExit;
    const handler = shouldMount ? onEntered : onExited;
    setStatus(shouldMount ? 'pre-entering' : 'pre-exiting');
    window.clearTimeout(timeoutRef.current);
    const preStateTimeout = window.setTimeout(() => {
      typeof preHandler === 'function' && preHandler();
      setStatus(shouldMount ? 'entering' : 'exiting');
    }, 10);
    timeoutRef.current = window.setTimeout(() => {
      window.clearTimeout(preStateTimeout);
      typeof handler === 'function' && handler();
      setStatus(shouldMount ? 'entered' : 'exited');
    }, transitionDuration);
  };
  useUpdateEffect(() => {
    handleStateChange(mounted);
  }, [mounted]);
  return {
    transitionDuration,
    transitionStatus,
    transitionTimingFunction: timingFunction,
  };
}
