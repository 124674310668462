import {
  View,
  Image,
  TouchableOpacity,
  Styled,
} from '@unthinkable/react-core-components';

export const SelectorContainer = Styled(View)`
  padding: 8px;
  margin: -8px;
  ${({styles}) => styles?.selectorContainer}
`;

export const SelectorIconContainer = Styled(View)`
  padding: 8px;
  margin: -8px;
${({styles}) => styles?.selectorIconContainer}
`;

export const SelectorIcon = Styled(Image)`
  width: 21px; 
  height: 21px;
  ${({styles}) => styles?.selectorIcon}
`;

export const CrossIcon = Styled(Image)`
  width: 16px; 
  height: 16px;
  ${({styles}) => styles?.crossIcon}
`;

export const ModalContainerNative = Styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  background-color: #00000077;
  ${({styles}) => styles?.modalContainerNative}
`;
