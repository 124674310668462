import {
  Platform,
  Styled,
  Text,
  TextInput,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {isMobile} from '@unthinkable/react-utils';

export const StyledInputContainer = Styled(TouchableOpacity).withConfig({
  shouldForwardProp: prop => !['focus', 'error'].includes(prop),
})`
border-width: 1px;
border-color: #EEEEED;
border-radius: 8px;
background-color: #FFFFFF;
padding-left: 12px;
padding-right: 12px;
padding-top: 6px;
padding-bottom: 6px;
min-height:48px;
justify-content: center;
${props => props.styles?.container}
${({focus, styles}) => (focus ? styles?.focus || {borderColor: '#B2EE7C'} : {})}
${({error, styles}) => (error ? styles?.error || {borderColor: 'red'} : {})}
`;

export const Input = Styled(TextInput)
  .withConfig({
    shouldForwardProp: prop => !['isFloatingLabel'].includes(prop),
  })
  .attrs(({placeholderTextColor, styles}) => {
    return {
      placeholderTextColor:
        placeholderTextColor || styles?.placeholderTextColor,
    };
  })`
  font-size: 14px;
  line-height: 18px;
  color: #19191A;
  width: 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
  ${props => props.styles?.input}
  ${({maxWidth}) => (maxWidth ? {maxWidth} : {})}
  ${({isFloatingLabel}) => (isFloatingLabel ? {marginTop: 12} : {})}
`;

export const FloatingContainer = Styled(TouchableOpacity)`
cursor:text;
position: absolute;
left:12px;
top:${({active}) => (active ? '6px' : '12px')};
transition: top 0.2s ease;
${({styles, active}) =>
  active ? styles?.activeFloatingContainer : styles?.floatingContainer}
${({disabled}) =>
  disabled
    ? {
        pointerEvents: 'none',
      }
    : void 0}
`;

export const FloatingText = Styled(Text)`
  font-size: 14px;
  color: #7D7D82;
${({styles}) => ({
  lineHeight: isMobile ? '24px' : '20px',
  ...styles?.floatingLabel,
})}
${({styles, active}) =>
  active
    ? {
        fontSize: 11,
        lineHeight: '14px',
        color: '#A3A3A3',
        fontWeight: 500,
        ...styles?.label,
      }
    : {}}
`;

export const StyledLabel = Styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #7D7D82;
  padding-bottom: 4px;
  ${props => props?.styles?.label}
`;

export const Asterisk = Styled(Text).attrs(
  Platform.OS == 'web' ? {as: 'span'} : {},
)`
  padding-left: 2px;
  color: red;
`;

export const StyledText = Styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #19191A;
  ${props => props?.styles?.text}
  ${({whiteSpace}) =>
    whiteSpace
      ? {
          whiteSpace,
        }
      : {}}
  ${({color}) => (color ? {color} : {})}
`;
