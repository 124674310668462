import React from 'react';
import {
  StyledButton,
  ContentContainer,
  Icon,
  ContentWrapper,
  Label,
  LoaderAbsoluteContainer,
} from './Styles';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';

const Button = props => {
  let {
    styles,
    gap = 4,
    onPress,
    text,
    icon,
    iconPosition = 'left',
    children,
    disabled,
    loading = false,
    renderLoader,
  } = props;

  if (typeof children === 'string') {
    text = children;
  }

  const iconComponent = icon ? <Icon source={icon} styles={styles} /> : void 0;

  const textComponent = text ? (
    <Label disabled={disabled} styles={styles} numberOfLines={2}>
      {text}
    </Label>
  ) : (
    void 0
  );

  return (
    <StyledButton
      styles={styles}
      disabled={disabled || loading}
      onPress={onPress}>
      <ContentContainer loading={loading} gap={gap}>
        {iconPosition === 'left' ? iconComponent : void 0}
        {textComponent}
        {iconPosition === 'right' ? iconComponent : void 0}
      </ContentContainer>
      {loading ? (
        <LoaderAbsoluteContainer>
          {renderLoader ? renderLoader(props) : <ActivityIndicator />}
        </LoaderAbsoluteContainer>
      ) : (
        void 0
      )}
    </StyledButton>
  );
};

export default Button;
