import React from 'react';
import {Styled, Text} from '@unthinkable/react-core-components';

const ErrorText = Styled(Text)`
font-size: 12px;
color: red;
padding-top: 5px;
${props => props?.styles?.errorText}
`;

const ErrorMessage = ({error, styles}) => {
  if (!error) {
    return null;
  }
  return <ErrorText styles={styles}>{error}</ErrorText>;
};

export default ErrorMessage;
