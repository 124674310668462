import React from 'react';
import {StyledInputContainer} from './Styles';

const InputContainer = props => {
  const {styles, focus, error, children, disabled, readOnly, inputRef} = props;
  return (
    <StyledInputContainer
      styles={styles}
      focus={focus}
      error={error}
      disabled={disabled || readOnly}
      onPress={() => {
        inputRef?.current?.focus?.();
      }}>
      {children}
    </StyledInputContainer>
  );
};

export default InputContainer;
