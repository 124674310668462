import React from 'react';
import {StyledText} from './Styles';

const BaseTextRender = ({value, ...props}) => {
  if (value === null || value === undefined) {
    value = '';
  } else if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  return <StyledText  {...props}>{value}</StyledText>;
};

export default BaseTextRender;
