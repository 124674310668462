import React from 'react';
import {
  View,
  Image,
  Text,
  Row,
  Styled,
} from '@unthinkable/react-core-components';

export const StyledView = Styled(View)`
  ${({containerStyle}) => containerStyle}`;

export const WeekView = Styled(View)`
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 5px;
      cursor: pointer;
  
  ${({styles: {weekComponentStyle} = {}, primary}) =>
    !!primary
      ? {
          ...weekComponentStyle?.viewStyle,
          ...weekComponentStyle?.primaryViewStyle,
        }
      : {...weekComponentStyle?.viewStyle}}`;

export const MonthView = Styled(View)`
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
 
  ${({containerStyle}) => containerStyle}`;

export const StyledContainer = Styled(View)`
  ${({containerStyle}) => containerStyle}`;

export const MonthContainer = Styled(View)`
        flex: 1;
        cursor: pointer;
        padding: 5px;
        justify-content: center;
        align-items: center;
  
  ${({styles}) => styles?.month_viewStyle}`;

export const MonthHeaderContainer = Styled(View)`
  flex-direction: row;
  align-items: center;
  ${({containerStyle}) => containerStyle}`;

export const YearHeaderContainer = Styled(View)`
       cursor: pointer;
       flex: 1;
       padding: 5px;
       justify-content: center;
       align-items: center;
  ${({containerStyle}) => containerStyle}`;

export const YearHeader = Styled(View)`
  flex-direction: row;
  align-items: center;
  ${({containerStyle}) => containerStyle}
  `;

export const YearContainer = Styled(View)`
  cursor: pointer;
  flex: 1;
  padding: 5px;
  justify-content: center;
  align-items:center;
  
  ${({styles}) => styles}`;

export const HeaderContainer = Styled(View)`
     flex-direction: row;
     align-items: center;
  ${({styles}) => styles}`;

export const WeekContainer = Styled(View)`
      flex-direction: row;
      padding-top: 5px;
      align-items: center;
  ${({containerStyle}) => containerStyle}`;

export const DateContainer = Styled(View)`
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({styles: {dateStyle} = {}, deActive, selected}) =>
    !!deActive
      ? {...dateStyle?.viewStyle, ...dateStyle?.deActiveViewStyle}
      : !!selected
      ? {...dateStyle?.viewStyle, ...dateStyle?.selectedViewStyle}
      : {...dateStyle?.viewStyle}}`;

export const DatesList = Styled(View)`
  cursor: pointer
  `;

export const PrevNextContainer = Styled(View)`
  width: 20px;
  height: 20px;
  background-color: #F0FAFF;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  ${({styles}) => styles?.viewStyle}
  `;

export const StyledImage = Styled(Image)`
  ${({styles}) => styles?.imageStyle}`;

export const YearHeaderText = Styled(Text)`
  font-size: 14px;
  font-family: Inter-SemiBold;
  line-height: 18px;
  color: #19191A;
  ${({textStyle}) => textStyle}`;

export const YearText = Styled(Text)`
  padding: 7px;
  font-size: 14px;
  font-family: Inter-Regular;
  line-height: 20px;
  color: #19191A;
  ${({textStyle}) => textStyle}`;

export const WeekText = Styled(Text)`
       text-align: center;
       color: #7D7D82;
       font-size: 14px;
       font-family: Inter-SemiBold;
       line-height: 18px;
  ${({styles: {weekComponentStyle} = {}, primary}) => {
    return !!primary
      ? {
          color: '#FF3B30',
          ...weekComponentStyle?.textStyle,
          ...weekComponentStyle?.primaryTextStyle,
        }
      : {...weekComponentStyle?.textStyle};
  }}`;

export const DateText = Styled(Text)`
  padding-left: 5px;
  padding-right: 5px;
  white-space: pre-wrap;
  font-size: 14px;
  font-family: Inter-Regular;
  line-height: 20px;
  color: #19191A;
  ${({styles = {}, deActive, selected}) => {
    return !!deActive
      ? {
          color: '#A3A3A3',
          ...styles?.dateStyle?.textStyle,
          ...styles?.dateStyle?.deActiveTextStyle,
        }
      : !!selected
      ? {
          color: '#32ADE6',
          borderBottomStyle: 'solid',
          borderBottomColor: '#32ADE6',
          borderBottomWidth: 2,
          ...styles?.dateStyle?.textStyle,
          ...styles?.dateStyle?.selectedTextStyle,
        }
      : {...styles?.dateStyle?.textStyle};
  }}`;

export const MonthYearText = Styled(Text)`
  font-size: 14px;
  font-family: Inter-SemiBold;
  line-height: 18px;
  color: #19191A;
  ${({styles}) => styles}`;

export const PrevNextText = Styled(Text)`
  color: #19191A;
  ${({styles}) => styles?.textStyle}`;

export const DateRow = Styled(Row)`
  flex:1;`;

export const YearRow = Styled(View)`
  
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  
  ${({containerStyle}) => containerStyle}
  `;

export const YearListContainer = Styled(View)`
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  ${({containerStyle}) => containerStyle}
  `;

export const RenderWrapper = Styled(View)`
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      font-family: Inter-SemiBold;
      line-height: 18px;
  ${({containerStyle}) => containerStyle}
  `;

export const MonthsRow = Styled(View)`
       flex-direction: row;
       justify-content: space-around;
       cursor: pointer;
       ${({containerStyle}) => containerStyle}
  `;

export const MonthText = Styled(Text)`
    padding: 7px;
    font-size: 14px;
    font-family: Inter-Regular;
    line-height: 20px;
    color: #19191A;
  ${({textStyle}) => textStyle}`;
