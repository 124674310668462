/**
 * TODO
 * Monu (1 October 2021)
 * if popper content is smaller than trigger button in width then arrow shows 2pixel out from content
 * Arrow size not working will see later
 * correction in arrow style when give border radius to content
 */
// package install
//  "@popperjs/core": "^2.10.1",
//  "react-popper": "^2.2.5"

import React, {useEffect, useRef, useState} from 'react';
import {usePopper} from 'react-popper';
import PropTypes from 'prop-types';
import './styles.css';
import {Transition} from '@unthinkable/react-transition';

const useUpdateEffect = (fn, deps) => {
  const mounted = useRef(false);

  if (typeof deps !== 'undefined' && !Array.isArray(deps)) {
    deps = [deps];
  } else if (Array.isArray(deps) && deps.length === 0) {
    // console.warn(
    //   'Using [] as the second argument makes useUpdateEffect a noop. The second argument should either be `undefined` or an array of length greater than 0.',
    // );
  }

  useEffect(
    () => {
      if (mounted.current) {
        fn();
      } else {
        mounted.current = true;
      }
    },
    // eslint-disable-next-line
    deps,
  );
  return mounted.current;
};

const Popper = ({
  referenceElement,
  children,
  mounted,
  transitionDuration,
  transitionTimingFunction,
  arrowClassName,
  position = 'bottom',
  placement = 'center',
  gutter = 5,
  arrowSize = 5,
  withArrow = false,
  transition = 'pop-top-left',
  zIndex = 1,
  forceUpdateDependencies = [],
  strategy = 'fixed',
}) => {
  const padding = withArrow ? gutter + arrowSize / 2 : gutter;
  const [popperElement, setPopperElement] = useState(null);
  const {styles, attributes, forceUpdate} = usePopper(
    referenceElement,
    popperElement,
    {
      strategy,
      placement: placement === 'center' ? position : `${position}-${placement}`,
      modifiers: [
        {name: 'offset', options: {offset: [0, padding]}},
        {
          name: 'flip',
          options: {
            // fallbackPlacements: ["bottom", "top", "left", "right"],
          },
        },
      ],
    },
  );

  useUpdateEffect(() => {
    typeof forceUpdate === 'function' && forceUpdate();
  }, forceUpdateDependencies);
  return (
    <Transition
      mounted={mounted}
      duration={transitionDuration}
      transition={transition}
      timingFunction={transitionTimingFunction}>
      {transitionStyles => (
        <div ref={setPopperElement} style={{zIndex, ...styles?.popper}}>
          <div
            style={transitionStyles}
            {...attributes.popper}
            role="tooltip"
            id="tooltip">
            {children}
            {withArrow && (
              <div
                className={arrowClassName}
                id="arrow"
                data-popper-arrow
                style={{
                  // height: arrowSize,//TODO
                  // width: arrowSize,//TODO
                  ...styles?.arrow,
                }}
              />
            )}
          </div>
        </div>
      )}
    </Transition>
  );
};

Popper.prototype = {
  /** Element at which popper should be attached */
  referenceElement: PropTypes.element,

  /** Popper content */
  children: PropTypes.node,

  /** True to show popper, false to hide */
  mounted: PropTypes.bool,

  /** Arrow inline styles */
  arrowStyle: PropTypes.object,

  /** useEffect dependencies to force update popper position */
  forceUpdateDependencies: [],

  /** Position relative to reference element */
  position: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),

  /** Placement relative to reference element */
  placement: PropTypes.oneOf(['start', 'center', 'end']),

  /** Spacing between element and popper in px  */
  gutter: PropTypes.number,

  /** Arrow size in px */
  arrowSize: PropTypes.number,

  /** Renders arrow if true */
  withArrow: PropTypes.bool,

  /** Popper z-index */
  zIndex: PropTypes.number,

  /** Customize mount/unmount transition */
  transition: PropTypes.oneOf([
    'fade',
    'scale',
    'scale-y',
    'scale-x',
    'skew-up',
    'skew-down',
    'rotate-left',
    'rotate-right',
    'slide-down',
    'slide-up',
    'slide-left',
    'slide-right',
    'pop-bottom-left',
    'pop-bottom-right',
    'pop-top-left',
    'pop-top-right',
  ]),

  /** Mount/unmount transition duration in ms */
  transitionDuration: PropTypes.number,

  /** Mount/unmount transition timing function, defaults to theme.transitionTimingFunction */
  transitionTimingFunction: PropTypes.string,
};

export default Popper;
