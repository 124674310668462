import React from 'react';
import {
  ChipContainer,
  ChipCrossIcon,
  ChipCrossIconContainer,
  ChipText,
  PrefixText,
  SuffixText,
} from './Styles';
import ChipCross from '../images/ChipCross.svg';
import {TouchableOpacity, View} from '@unthinkable/react-core-components';
import {hasValue} from '@unthinkable/react-utils';

const Chip = ({
  styles,
  title,
  value,
  color,
  textColor,
  prefixTextColor,
  suffixTextColor,
  borderColor,
  borderWidth,
  crossable,
  borderRadius,
  onCross,
  prefix,
  suffix,
  gap = 6,
  maxWidth,
  numberOfLines = 1,
  children,
  onPress,
}) => {
  if (typeof prefix === 'function') prefix = prefix({value});
  if (typeof suffix === 'function') suffix = suffix({value});

  if (!children) {
    if (!hasValue(value)) {
      return null;
    }
    title = title || value;
    if (typeof title !== 'string') {
      title = JSON.stringify(title);
    }
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    children = (
      <ChipText
        styles={styles}
        title={title}
        color={textColor}
        numberOfLines={numberOfLines}>
        {typeof prefix === 'string' ? (
          <PrefixText styles={styles} color={prefixTextColor}>
            {prefix}
          </PrefixText>
        ) : (
          void 0
        )}
        {value}
        {typeof suffix === 'string' ? (
          <SuffixText styles={styles} color={suffixTextColor}>
            {suffix}
          </SuffixText>
        ) : (
          void 0
        )}
      </ChipText>
    );
  }

  let renderComponent = (
    <View style={{alignItems: 'flex-start'}}>
      <ChipContainer
        gap={gap}
        styles={styles}
        maxWidth={maxWidth}
        borderColor={borderColor}
        borderWidth={borderWidth}
        backgroundColor={color}
        borderRadius={borderRadius}>
        {typeof prefix !== 'string' ? prefix : void 0}
        {children}
        {typeof suffix !== 'string' ? suffix : void 0}
        {onCross && crossable !== false ? (
          <ChipCrossIconContainer
            styles={styles}
            onPress={() => {
              onCross();
            }}>
            <ChipCrossIcon
              styles={styles}
              source={styles?.crossIcon || ChipCross}
            />
          </ChipCrossIconContainer>
        ) : (
          void 0
        )}
      </ChipContainer>
    </View>
  );
  if (onPress) {
    renderComponent = (
      <TouchableOpacity
        onPress={e => {
          onPress(value, e);
        }}>
        {renderComponent}
      </TouchableOpacity>
    );
  }
  return renderComponent;
};

export default Chip;
