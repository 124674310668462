import {
  Image,
  Row,
  Styled,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import DateRange from '../../images/DateRange.svg';
import Previous from '../../images/Previous.svg';
import Next from '../../images/Next.svg';
import PreviousWithBg from '../../images/PreviousWithBg.svg';
import NextWithBg from '../../images/NextWithBg.svg';

export const Container = Styled(Row)`
    gap: 8px;
    padding: 4px 8px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    align-items: center;
    ${props => props?.styles?.container}
`;

export const DateRangeIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.dateRangeIcon || DateRange,
}))`
    width: 20px;
    height: 20px;
    ${props => props?.styles?.dateRange}
`;

export const SelectedLabelContainer = Styled(View)`
    padding: 4px 8px;    
    background-color: #F7F7F7;
    border-radius: 6px;
    ${props => props?.styles?.selectedLabelContainer}
`;

export const SelectedLabel = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    color: #737373;
    ${props => props?.styles?.selectedLabel}
`;

export const SelectedValue = Styled(Text)`
    font-size: 13px;
    line-height: 18px;
    color: #1A1A1A;
    ${props => props?.styles?.selectedValue}
`;

export const PrevNextButton = Styled(TouchableOpacity)`
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    ${props => props?.styles?.prevNextButton}
    ${props => props?.disabled && {opacity: 0.5}}
`;

export const PreviousIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.previousIcon || Previous,
}))`
    width: 12px;
    height: 12px;
    ${props => props?.styles?.previousIconStyle}
`;

export const NextIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.nextIcon || Next,
}))`
    width: 12px;
    height: 12px;
    ${props => props?.styles?.nextIconStyle}
`;

export const ModalContainer = Styled(View)`
    gap: 12px;
    ${props => props?.styles?.modalContainer}
`;

export const ModalHeader = Styled(Row)`
    padding: 12px;
    gap: 8px;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    ${props => props?.styles?.modalHeader}
`;

export const HeaderTitleContainer = Styled(Row)`
    flex: 1;
    align-items: center;
    gap: 8px;
    ${props => props?.styles?.headerTitleContainer}
`;

export const HeaderText = Styled(Text)`
    ${props => props?.styles?.headerText}
`;

export const SwitchLabel = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    color: #737373;
    ${props => props?.styles?.switchLabel}
`;

export const ModalBody = Styled(View)`
    gap: 8px;
    padding: 0px 12px;
    ${props => props?.styles?.modalBody}
`;

export const PrimaryTitle = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    color: #737373;
    flex: 1;
    ${props => props?.styles?.primaryTitle}
`;

export const SecondaryTitle = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    color: #A3A3A3;
    ${props => props?.styles?.secondaryTitle}
`;

export const YearContainer = Styled(TouchableOpacity)`
    flex-direction: row;    
    gap: 12px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #E0E0E0;
    ${props => props?.styles?.yearContainer}
    ${props => `
    &:hover {
        background-color:${props?.styles?.hoverColor || '#EDEDED'};
    }
    `}
    ${props =>
      props?.isSelected && {
        borderColor: '#7DCD28',
        ...props?.styles?.selectedYearContainer,
      }}
`;

export const YearRow = Styled(Row)`
    gap: 10px;
    ${props => props?.styles?.yearRow}
`;

export const YearText = Styled(Text)`
    font-size: 14px;
    line-height: 20px;
    color: #1A1A1A;
    ${props => props?.styles?.yearText}
`;

export const YearPrevNextButton = Styled(TouchableOpacity)`
    ${props => props?.styles?.yearPrevNextButton}
    ${props => props?.disabled && {opacity: 0.5}}
`;

export const YearPreviousIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.yearPreviousIcon || PreviousWithBg,
}))`
    width: 20px;
    height: 20px;
    ${props => props?.styles?.yearPreviousIconStyle}
`;

export const YearNextIcon = Styled(Image).attrs(props => ({
  source: props?.styles?.yearNextIcon || NextWithBg,
}))`
    width: 20px;
    height: 20px;
    ${props => props?.styles?.yearNextIconStyle}
`;

export const QuarterContainer = Styled(View)`
    gap: 10px;
    padding: 8px;
    border-radius: 6px;
    background-color: #FAFAFA;

    ${props => props?.styles?.quarterContainer}
`;

export const QuarterRow = Styled(Row)`
    gap: 16px;
    ${props => props?.styles?.quarterRow}
`;

export const QuarterItemContainer = Styled(TouchableOpacity)`
    flex: 1;
    height: 32px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    ${props => props?.styles?.quarterItemContainer}
    ${props => `
    &:hover {
        background-color:${props?.styles?.hoverColor || '#EDEDED'};
    }
    `}
    ${props =>
      props?.isSelected && {
        borderColor: '#7DCD28',
        ...props?.styles?.selectedQuarterItemContainer,
      }}
`;

export const QuarterLabel = Styled(Text)`
    font-size: 14px;
    line-height: 20px;
    color: #1A1A1A;
    ${props => props?.styles?.quarterLabel}
`;

export const MonthWiseContainer = Styled(Row)`
    gap: 12px;
    height: 36px;
    padding: 0px 8px;
    border-radius: 6px;
    background-color: #FAFAFA;
    align-items: center;
    ${props => props?.styles?.monthWiseContainer}
`;

export const Separator = Styled(View)`
    height: 1px;
    width: 100%;
    background-color: #EDEDED;
    ${props => props?.styles?.separator}
`;

export const MonthContainer = Styled(View)`
    gap: 10px;
    padding: 8px;
    border-radius: 6px;
    background-color: #FAFAFA;
    ${props => props?.styles?.monthContainer}
`;

export const MonthItemContainer = Styled(TouchableOpacity)`
    height: 32px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    ${props => props?.styles?.monthItemContainer}
    ${props => `
    &:hover {
        background-color:${props?.styles?.hoverColor || '#EDEDED'};
    }
    `}
    ${props =>
      props?.isSelected && {
        borderColor: '#7DCD28',
        ...props?.styles?.selectedMonthItemContainer,
      }}
`;

export const MonthLabel = Styled(Text)`
    font-size: 14px;
    line-height: 20px;
    color: #1A1A1A;
    ${props => props?.styles?.monthLabel}
`;

export const OtherContainer = Styled(View)`
    gap: 10px;
    padding: 8px;
    border-radius: 6px;
    background-color: #FAFAFA;
    ${props => props?.styles?.otherContainer}
`;

export const ModalFooter = Styled(Row)`
    gap: 28px;
    padding: 8px 12px;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #E0E0E0;
    ${props => props?.styles?.modalFooter}
`;

export const CancelButton = Styled(TouchableOpacity)`
    ${props => props?.styles?.cancelButton}
`;

export const CancelText = Styled(Text)`
    font-size: 14px;
    line-height: 18px;
    color: #007AFF;
    ${props => props?.styles?.cancelText}
`;

export const CustomPickerContainer = Styled(Row)`
    align-items: center;
    ${props => props?.styles?.customPickerContainer}
`;

export const StartDateContainer = Styled(View)`
    width: 50%;
    padding-right: 8px;
    ${props => props?.styles?.startDateContainer}
`;

export const EndDateContainer = Styled(View)`
    width: 50%;
    padding-left: 8px;
    ${props => props?.styles?.endDateContainer}
`;
