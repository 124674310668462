import React, {forwardRef} from 'react';
import {Row, TouchableOpacity, View} from '@unthinkable/react-core-components';
import './date';
import CrossImage from '../images/Cross.svg';
import Calendar from '../images/Calendar.svg';
import {
  SelectorIconContainer,
  SelectorContainer,
  CrossIcon,
  SelectorIcon,
} from './styles/DatePickerStyles';
import {Input} from '@unthinkable/react-input';

export const DateInputRender = forwardRef(
  (
    {
      styles = {},
      value,
      placeholder,
      disabled,
      readOnly,
      onBlur,
      onFocus,
      onChangeValue,
      showIcon = true,
      removeValue,
      isFloatingLabel,
      openModal,
    },
    ref,
  ) => {
    const renderIcon = () => {
      if (!showIcon) {
        return null;
      }
      let showCross = value && !disabled && !readOnly;

      let iconRender = (
        <SelectorIconContainer styles={styles}>
          {showCross ? (
            <TouchableOpacity onPress={removeValue}>
              <CrossIcon source={styles?.cross || CrossImage} styles={styles} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={openModal}>
              <SelectorIcon
                source={styles?.selector || Calendar}
                styles={styles}
              />
            </TouchableOpacity>
          )}
        </SelectorIconContainer>
      );

      return (
        <SelectorContainer styles={styles}>{iconRender}</SelectorContainer>
      );
    };

    return (
      <Row style={{alignItems: 'center'}}>
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
            ...(isFloatingLabel ? {marginTop: 12} : {}),
          }}>
          <Input
            ref={ref}
            styles={styles}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={readOnly}
            disabled={disabled}
            onChangeValue={onChangeValue}
            placeholder={placeholder}
          />
        </View>
        {renderIcon()}
      </Row>
    );
  },
);
