import React from 'react';
import BaseText from './BaseText';
import {resolvePropStyle} from '../Utility';
import Styled from '../styled-components';
import Platform from '../Platform';

const StyledText = Styled(BaseText).withConfig({
  shouldForwardProp: prop => !['overrideStyle', 'styles'].includes(prop),
})`
    ${({overrideStyle}) => overrideStyle}
  `;

const Text = ({
  style: styleProp = {},
  numberOfLines: numberOfLinesProp,
  as,
  onPress,
  ...rest
}) => {
  let {numberOfLines, style} = resolvePropStyle(styleProp, 'numberOfLines');
  return (
    <StyledText
      onClick={onPress}
      {...rest}
      as={Platform.OS === 'web' ? as : void 0}
      overrideStyle={style}
      numberOfLines={
        numberOfLines !== undefined ? numberOfLines : numberOfLinesProp
      }
    />
  );
};

export default Text;
