import React from 'react';
import {Text, View} from '@unthinkable/react-core-components';

export const LoadingScreen = () => {
  return (
    <View style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
      <Text>{'loading...'}</Text>
    </View>
  );
};
