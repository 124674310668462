import React from 'react';
import View from './View';
import Styled from './styled-components';

const StyledWrap = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['overrideStyle', 'gap', 'columnGap', 'rowGap'].includes(prop),
})`
  flex-direction: row;
  flex-wrap: wrap;
  ${props => props.overrideStyle}
  ${props => (typeof props.gap === 'number' ? {gap: props.gap} : {})}
  ${props => (typeof props.rowGap === 'number' ? {rowGap: props.rowGap} : {})}
  ${props =>
    typeof props.columnGap === 'number' ? {columnGap: props.columnGap} : {}}
`;

const Wrap = ({children, style, ...restProps}) => {
  return (
    <StyledWrap overrideStyle={style} {...restProps}>
      {children}
    </StyledWrap>
  );
};

export default Wrap;
