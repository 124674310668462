import React from 'react';
import {
  Animated,
  Styled,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import { isMobile } from '@unthinkable/react-utils';

export const SwitchTrack = Styled(TouchableOpacity)`
height: 20px;
width: 40px;
background-color: #787878;
border-radius: 14px;

${({styles, value, trackColor, activeTrackColor}) => {
  return {
    ...styles?.track,
    ...(trackColor ? {backgroundColor: trackColor} : void 0),
    ...(value && {
      backgroundColor: '#7DCD28',
      ...styles?.activeTrack,
      ...(activeTrackColor ? {backgroundColor: activeTrackColor} : void 0),
    }),
  };
}}`;

export const SwitchThumb = Styled(Animated.View)`
      height: 18px;
      width: 18px;
      border-radius: 9px;
      background-color: #fff;
      position: absolute;
      transition:left 0.5s;
      top: 1px;
      bottom: 1px;
${({
  styles,
  value,
  thumbColor,
  trackColor,
  activeThumbColor,
  activeTrackColor,
  thumbBorderColor,
}) => {
  return {
    ...isMobile? {height:17, width:17}:{},
    ...styles?.thumb,
    // left: value ? 21 : 1,
    ...(value ? {right: 1} : {left: 1}), //animation not working after giving this style
    ...((trackColor || thumbColor) && {
      backgroundColor: trackColor || thumbColor,
    }),
    ...(value &&
      (activeTrackColor || activeThumbColor) && {
        borderColor: activeTrackColor || activeThumbColor,
      }),
    ...(thumbBorderColor && {borderColor: thumbBorderColor}),
  };
}}
`;
